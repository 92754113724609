@import './colors.scss';

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

html, body {
  height: 100%;
  font-family: 'Fira Sans';
  background-color: map-get($gray, 10);
  // color: map-get($gray, 80);
}

#root {
  height: 100%;
}
