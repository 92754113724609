@import "../colors.scss";

.sign-in-box {
    width: 370px;
    max-width: 95vw;
    padding: 60px 32px;
    text-align: center;
    transition: 1s all;
    animation: fade-in 1s 1 ease-in;
  
    .heading {
      margin-bottom: 42px;

      .gamename {
        color: $neon;
        font-family: 'Fira Sans';
        font-weight: 400;
        font-size: 32px;
        line-height: 1.5;
        letter-spacing: -0.07em;
      }
      .subtitle {
        color: map-get($gray, 50);
        font-weight: 300;
      }
    }
  
    .form {
      > div {
        margin-bottom: 40px;
      }

      .message {
        color: map-get($gray, 80);
        margin-top: 6px;
        margin-bottom: 18px;
        font-size: 14px;
        
      }
    }

    .message {
      color: map-get($gray, 80);
    }

    .actions {
      button {
        display: block;
        margin-left: auto;
        margin-right: auto;

        &:not(:last-child) {
          margin-bottom: 26px;
        }
      }
    }
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  