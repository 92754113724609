@import '../colors.scss';
.game-global-status {
    display: flex;
    justify-content: space-between;

    .status-item {
        font-size: 16px;
        letter-spacing: 0.05rem;
        font-family: 'Fira Sans';

        .status-item-label {
            text-transform: uppercase;
            font-size: 12px;
            color: $text-secondary;
            padding-right: 4px;
        }

        .status-item-value {
            font-weight: bold;
            color: $text-primary
        }
    }
}
