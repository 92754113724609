@import "../colors.scss";
@import "../keyframes.scss";

.gameplay {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  //scrollbar
  ::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
    /* or add it to the track */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: map-get($gray, 30);
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    background-clip: padding-box;
  }

  .content-area {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    overflow: hidden;

    .sidebar-left-wrapper {
      height: 100%;
      padding-right: 32px;

      >* {
        height: 100%;
      }
    }

    .game {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .game-info {
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding: 0 12px;
      }

      .player-info {
        text-align: center;
        z-index: 0;

        .game-status-msg {
          margin-top: 12px;
        }
      }
    }

    .sidebar-right-wrapper {
      height: 100%;
      overflow-y: auto;
      display: grid;
      padding-left: 32px;

      .sidebar-right {
        align-self: center;
        display: flex;
        padding-right: 16px;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 16px;

        .title {
          font-family: "fira sans";
          text-transform: uppercase;
          color: $text-secondary;
          margin-bottom: 16px;
          font-size: 16px;
          line-height: 1.3;
          letter-spacing: .75px;
        }
      }

      .mission-gauges {
        .title  .game-class {
            font-weight: bold;
        }

        .gauges {
          > :not(:last-child) {
            margin-bottom: 24px;
          }
        }

        .mission-backdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          >div {
            position: absolute;
            background: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }

  .control-area {
    width: 100%;
    height: 134px;
    flex-grow: 0;
    background: map-get($gray, 0);

    #eliminate-message {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      ;
      column-gap: 12px;

      span {
        font-weight: bold;
        color: $text-secondary;
      }
    }
  }
}