@import '../colors.scss';

.time-bar {
  width: 100%;
  height: 8px;
  display: flex;
  margin-bottom: -8px;

  .time-beat {
    flex-basis: 100%;
    background: #222;

    .time-beat-box {
      background-color: map-get($gray, 0);
      width: 100%;
      height: 100%;
    }

    &.elapsed .time-beat-box {
      width: 0%;
      transition: width linear 1s;
      margin-left: auto;
    }
  }
}
