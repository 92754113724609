@import "../colors.scss";

.vertical-timer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;

    .infinite .finite { //remove?
        background-color: $neon;
        border-radius: 0 0 6px 6px;
    }
}

@keyframes infiniteTimer {
    0% {
        transform: translateY(99%) scaleY(10%);
    }

    10% {
        transform: translateY(99%) scaleY(10%);
    }

    50% {
        transform: scaleY(50%);
    }

    90% {
        transform: translateY(-1%) scaleY(10%);

    }

    100% {
        transform: translateY(-10%) scaleY(10%);
    }
}

.finite {
    width: 100%;
    transition: height 0.2s;
    border-radius: 0 0 6px 6px;
}

.infinite {
    // animation: infiniteTimer 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transform-origin: top;
    border-radius: 6px;
}