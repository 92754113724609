@import '../colors.scss';

.new-board {
    position: relative;
    padding: 24px;
    margin-left: auto;
    margin-right: auto;
    z-index: 0;
    overflow: hidden;

    .cells {
        position: relative;
        width: fit-content;
        height: fit-content;
        transition: transform 0.3s;

        .cell-row {
            width: fit-content;
            line-height: 0;
            display: flex;

            &:not(:last-child) {
                margin-bottom: 4px;
            }
        }

        .cell {
            display: inline-block;
            border-radius: 8px;
            border-width: 1px;
            border-style: solid;
            transition: background-color 0.5s, border-color 0.5s;
            transition-delay: 0.3s;
            position: relative;

            &:not(:last-child) {
                margin-right: 4px;
            }

            .blending-rect {
                position: absolute;
                border-radius: 2px;

                &.highlight {
                    background-color: rgba(255, 255, 255, 0.3);
                }

                &.shadow {
                    background-color: rgba(0, 0, 0, 0.3);
                }
            }
        }
    }

    .player-markers {
        .player-marker {
            position: absolute;
            border-radius: 50%;
            transition: all 1s;
            animation: marker-horizontal-shaking 1s infinite;

            &.me {
                animation: floating 1s infinite ease-in-out;
            }

            &.entering {
                animation: slide-in-right 0.3s 1 ease-out;
            }
            &.eliminated {
                background-color: map-get($gray, 40);
                border-color: map-get($gray, 60);
                animation: simple-fade-out 1s 1 forwards ease-out;
                animation-delay: 0.8s;
            }
            &.next {
                font-size: 20px;
                font-weight: bold;
                text-align: center;
                color: #fff;
                .shape-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                }
                &.direction-u {
                    animation: spin-slide-u 1.5s infinite ease-in-out;
                    .shape-wrapper {
                        // top: -10px;
                        // left: -6px;
                        transform: rotate(-90deg);
                    }
                }
                &.direction-d {
                    animation: spin-slide-d 1.5s infinite ease-in-out;
                    .shape-wrapper {
                        // top: -4px;
                        // left: -6px;
                        transform: rotate(90deg);
                    }
                }
                &.direction-l {
                    animation: spin-slide-l 1.5s infinite ease-in-out;
                    .shape-wrapper {
                        // top: -6px;
                        // left: 2px;
                        transform: rotate(180deg);
                    }
                }
                &.direction-r {
                    .shape-wrapper {
                        // top: -6px;
                        // left: -2px;
                    }
                    animation: spin-slide-r 1.5s infinite ease-in-out;
                }
            }

            .player-marker-inner {
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 2px;

                .marker-border {
                    border: 2px solid black;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }

                .card-bubble {
                    text-align: center;
                    font-size: 16px;
                    font-weight: bold;
                    width: 36px;
                    padding: 2px 0;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -100%);
                    background: #fff;
                    z-index: 1;
                    border-radius: 2px;
                    animation: fade-out 2s 1;
                    opacity: 0;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        width: 0;
                        height: 0;
                        border: 6px solid transparent;
                        border-top-color: #fff;
                        border-bottom: 0;
                        margin-left: -6px;
                        margin-bottom: -5px;
                    }
                }
            }
        }
    }

    .board-overlay {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    .outer-border-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        padding: 12px;
        pointer-events: none;

        .outer-border {
            border: 1px solid map-get($gray, 50);
            border-radius: 8px;
            width: 100%;
            height: 100%;
            background-color: #000;
        }

        &.class1, &.class2 {
            .outer-border {
                border-width: 3px;
            }
        }

        &.class2 {
            .outer-border {
                border-color: #80FFDB;
            }
        }

        .board-glow {
            position: relative;
            opacity: 0;
            transition: all 0.5s;
            z-index: -1;
            &:before,
            &:after {
                content: '';
                width: 90%;
                aspect-ratio: 1;
                border-radius: 20%;
                position: absolute;
                top: 5%;
                left: 5%;
                z-index: -1;
                // box-shadow: 10px -25px 50px 10px map-get($gray, 50), 14px -20px 50px 10px map-get($gray, 50), -20px -25px 50px 10px map-get($gray, 50), 10px 25px 50px 10px map-get($gray, 50);
                animation: glow 2.5s ease-in-out alternate-reverse infinite;
                transform-origin: center;
            }
            &:after {
                transform: rotate(45deg);
                border-radius: 40%;
            }
        }

        &.class1 {
            .board-glow {
                opacity: 0.7;
                &:before,
                &:after {
                    box-shadow: 10px -25px 50px 10px map-get($gray, 50), 14px -20px 50px 10px map-get($gray, 50), -20px -25px 50px 10px map-get($gray, 50), 10px 25px 50px 10px map-get($gray, 50);
                }
                
            }
        }

        &.class2 {
            .board-glow {
                opacity: 1;
                &:before,
                &:after {
                    box-shadow: 10px -25px 50px 10px #80FFDB, 14px -20px 50px 10px #80FFDB, -20px -25px 50px 10px #80FFDB, 10px 25px 50px 10px #80FFDB;
                }
            }
        }

    }
}

@keyframes glow {
    from {
        aspect-ratio: 1;
    }

    50% {
        aspect-ratio: 1.04;
    }

    to {
        transform: translate(-4%, 3%);
        aspect-ratio: 0.95
    }
}