@import '../colors.scss';

.grid {
    display: grid;
    grid-template-columns: 320px 1fr;
    grid-template-rows: repeat(12, 64px) 1fr;
    padding: 0 64px 48px;
    column-gap: 16px;
    min-height: 100dvh;
    height: fit-content;
}

.profile {
    color: $text-primary;
    .profile-inner {
        position: relative;
        z-index: 1;
    }
    .player-profile-stats {
        grid-row: 3 / 10 span;
        width: 320px;
        padding: 0 32px;
        display: grid;
        grid-template-rows: subgrid;
        background: #00000033;
        border: map-get($gray, 20) solid 1px;

        img {
            width: 24px;
            margin-right: 16px;
        }
        
        .player-tag {
            font-family: 'Fira Sans';
            font-size: 28px;
            font-weight: 100;
            vertical-align: middle;
            margin-right: 10px;
        }

        .profile-row {
            width: 100%;
            grid-row:1 span;
            justify-self: center;
            align-self: start;
            &:first-of-type{
                gap: 12px;
            }
            &.overall {
                .level-tones > * {
                        display: inline-block;
                    }
                .to-next {
                    text-align: right;
                    font-family: 'Fira Sans';
                    font-size: 12px;
                    color: $text-secondary;
                    .next {
                    color: $text-primary;
                        font-weight: bold;
                        margin-right: 8px;
                    }
                }
                :global(.chart-values){
                    fill: $text-secondary;
                }
                :global( .pie-value){
                    font-weight: bold;
                }
            }
            &.wins, &.tones, &.top-class-played, &.top-class-won {
                display: flex;
                align-items: start;
            }

            &.rate {
                text-align: center;
                grid-row: 4 span;
                align-self: end;
            }
        }
    }

    .player-tones {
        grid-row: 3 / 9 span;
        display: grid;
        grid-template-rows: subgrid;
        grid-template-columns: subgrid;
        font-family: 'Fira Sans';

        > * {
            justify-self: center;
        }
        > :last-child{
            grid-row: 4 / 8 span;
            padding-bottom: 48px;
            height: fit-content;
        }

            .tone-count {
                grid-row: 2 / 1 span;
                grid-column: 1 span;
                padding-bottom: 7px;
                .count {
                    line-height: 1;
                    background: linear-gradient(180deg, #80FFDB 0%, #48B2BE 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 54px;
                    font-weight: 500;
                    margin-right: 10px;
                }
                .text {
                    color: $text-primary;
                    font-size: 24px;
                    vertical-align: super;
                }
            }

            .tone-count-unique {
                grid-column: 1 span;
                grid-row: 3 / 1 span;
                font-size: 13px;
                font-weight: 400;
                .count {
                    letter-spacing: 1px;
                    font-weight: bold;
                    margin-right: 4px;
                }
            }
    }
}
