@use 'sass:color';
@import '../colors.scss';

.game-missions {
    color: $text-secondary;
    max-width: 100%;
    letter-spacing: 0.8px;
    container: mission / inline-size;

    .missions {
        padding-bottom: 4px;
        max-width: 350px;
    }

    @container mission (max-width: 250px) {
        .game-mission .mission-stat .stat {
            width: 50px;
        }
    }

    @container mission (max-width: 200px) {
        .game-mission {
            flex-direction: column;
            align-items: flex-start;

            .mission-body {
                border-right: none;
                border-bottom: 1px solid map-get($gray, 20);
            }

            .mission-stat {
                text-align: left;
                padding-top: 6px;

                .stat {
                    text-align: left;
                    padding: 0;
                }

            }
        }
    }
}

.game-mission {
    font-family: 'Fira Sans';
    color: $text-primary;
    display: flex;
    align-items: center;
    font-size: 14px;

    .mission-body {
        padding: 8px;
        font-weight: 300;
        border-right: 1px solid map-get($gray, 20);
        flex-grow: 1;
        flex-shrink: 1;
    }

    .mission-stat {
        padding: 8px;
        font-weight: bolder;
        text-align: center;
        flex-grow: 0;
        flex-shrink: 0;

        .stat {
            display: inline-block;
            text-align: right;
            width: 75px;
            padding: 0 4px;
        }

        i {
            &:before {
                transition: all 0.7s;
            }
            &.steady:before {
                transform: rotate(0) translateY(0);
            }
            &.increase:before {
                transform: rotate(-45deg) translateY(-4px);
            }
            &.decrease:before {
                transform: rotate(45deg) translateY(4px);
            }
        }
    }

    &.accomplished {
        border-left: 4px solid $neon;
        .mission-stat {
            .stat {
                color: $text-primary;
            }
        }
    }
    &.not-accomplished {
        border-left: 4px solid rgba($alert-bg, .3);
        .mission-stat {
            .stat {
                color: $alert-text;
            }
        }
    }

    &.final {
        color: $text-secondary;
        &.accomplished {
            background-color: adjust-color($neon, $alpha: -0.7);
        }
        &.not-accomplished {
            background-color: adjust-color($alert-bg, $alpha: -0.7);
        }
    }

    &:not(.final) {
        background-color: #000;
    }

    &:not(:last-child) {
        margin-bottom: 12px;
    }
}