@import "../keyframes.scss";

#tone-stack {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: flex-start;
    gap: 0px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    padding: 24px;
    overflow: hidden;

    .tone-stack-block-wrapper {
        padding: 6px;
    }

    .tone-stack-block, .tone-stack-reserved-spot {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        transform: rotate(45deg);
        opacity: 0.5;
        transition: all 0.2s;
        animation: appear-spin 1s 1 linear;

        // &:hover {
        //     opacity: 1;
        //     transform: rotate(360deg) scale(1.8);
        // }
    }

    .tone-stack-block-wrapper:hover {
        .tone-stack-block, .tone-stack-reserved-spot {
            opacity: 1;
            transform: rotate(360deg) scale(2);
        }
    }
}
