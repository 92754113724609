@import "../colors.scss";

$base-width: 250px;

.how-to-play {
    color: map-get($gray, 80);
    font-size: 14px;
    .content-wrapper {
        width: calc($base-width * 3);
        height: calc($base-width * 2);
        position: relative;
        
        .step {
            position: absolute;
            display: flex;
            flex-wrap: nowrap;
            padding: 32px;

            &.step-1 {
                width: calc($base-width * 2);
                height: $base-width;
                top: 0;
                left: 0;
                border-bottom: 1px solid map-get($gray, 20);

                .text-block {
                    flex-grow: 1;
                    padding-right: 8px;
                }

                .image-block {
                    flex-basis: 160px;
                }
            }
            &.step-2 {
                width: calc($base-width * 2);
                height: $base-width;
                top: $base-width;
                left: 0;
                flex-direction: column;
                justify-content: space-around;
            }
            &.step-3 {
                width: $base-width;
                height: calc($base-width * 2);
                top: 0;
                left: calc($base-width * 2);
                border-left: 1px solid map-get($gray, 20);
                flex-direction: column;
                justify-content: space-evenly;
            }

            .image-block {
                flex-grow: 0;
                flex-shrink: 0;
                position: relative;
                img {
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .text-block {
                color: #fff;
                font-size: 16px;
                line-height: 1.4;

                .number {
                    font-size: 18px;
                    font-weight: bold;
                }

                .highlighted {
                    font-weight: bold;
                    color: $neon;
                }
            }
        }
    }
}