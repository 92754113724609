@import "../colors.scss";

.game-waiting {
    display: flex;
    min-height: 100vh;
    margin: -1rem 0;
    background: map-get($gray, 10);
    font-family: 'Fira Sans';
    font-size: 14px;
    line-height: 2.4;

    .waiting-hint{
        width: 450px;
        height: 100vh;
        padding: 2rem 0 0 64px;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        color: map-get($gray, 70);
        font-weight: 400;
        background: map-get($gray, 0);
        border-right: 1px solid map-get($gray, 20);
        font-family: 'Fira Sans';
        font-size: 15px;    
        line-height: 2.4;
        display: flex;
        align-items: center;

        .vertical-timer-wrapper {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .waiting-players {
        margin-left: 450px;
        flex-grow: 2;
        position: relative;
        max-width: none;
        display: flex;
        align-items: center;
        justify-content: center;

        .players-inner {
            width: 95%;
            position: relative;
            z-index: 10;
        }

        .heading {
            font-weight: bold;
            margin-bottom: 32px;
            font-size: 16px;
            text-align: center;
            
            .status-message, .blinker {
                color: map-get($gray, 80);
            }

            .blinker {
                margin: 0 8px 0 2px;
            }
            
            .player-count {
                color: map-get($gray, 60);
            }
        }

        .players {
            width: 100%;
            min-height: 300px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            gap: 12px 16px;

            @media screen and (max-width: 1100px) {
                justify-content: center;
            }
            .player {
                width: 279px;
                height: 34px;
                border: 1px solid map-get($gray, 20);
                border-radius: 2px;
                padding: 0 15px 0 12px;
                animation-name: fade-in;
                animation-duration: 1s;
                animation-iteration-count: 1;
                color: $text-secondary;
                white-space: nowrap;
                background-color: rgba(0, 0, 0, 0.5);
                display: flex;
                align-items: center;
                gap: 8px;
                font-family: 'Fira Mono';

                &.me {
                    border-color: $neon;
                }

                .tag-wrapper {
                    min-width: 34px;
                }

                .level-tag {
                    display: inline-flex;

                }

                .player-tag {
                    flex: 1;
                    line-height: 14px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                   color: map-get($gray, 80);
                }

                .tones {
                    width: 34px;
                    text-align: right;
                    color: $text-secondary;
                }
                i {
                    color: $text-secondary;
                }
            }
        }
    
        .elapsed-time {
            width: 56px;
            height: 56px;
            margin-left: auto;
            line-height: 54px;
            text-align: center;
            font-size: 22px;
            background: #fff;
            color: #222;
        }

        .bg-wrapper {
            position: fixed;
            height: 100vh;
            width: calc(100% - 450px);
            bottom: 0;
            right: 0;
        }
    }

    .floating-menu {
        position: fixed;
        z-index: 1;
        left: 50%;
        bottom: 32px;
        transform: translateX(-50%);
        padding: 16px 24px;
        border: 1px solid map-get($gray, 20);
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.3);

        button {
            display: inline-block;
            &:not(:last-child) {
                margin-right: 24px;
            }
        }
    }
}

@keyframes blinker {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

.blinker {
    animation-name: blinker;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

@keyframes semi-blinker {
    0% { opacity: 0; }
    15% { opacity: 1; }
    85% { opacity: 1; }
    100% { opacity: 0; }
}

.semi-blinker {
    animation-name: semi-blinker;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes color-rotation {
    0% { background-color: #7fdbda; }
    25% { background-color: #F55353; }
    50% { background-color: #FEB139; }
    75% { background-color: #FFEA11; }
    100% { background-color: #7fdbda; }
}
