body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.new-board {
  z-index: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  position: relative;
  overflow: hidden;
}

.new-board .cells {
  width: fit-content;
  height: fit-content;
  transition: transform .3s;
  position: relative;
}

.new-board .cells .cell-row {
  width: fit-content;
  line-height: 0;
  display: flex;
}

.new-board .cells .cell-row:not(:last-child) {
  margin-bottom: 4px;
}

.new-board .cells .cell {
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  transition: background-color .5s .3s, border-color .5s .3s;
  display: inline-block;
  position: relative;
}

.new-board .cells .cell:not(:last-child) {
  margin-right: 4px;
}

.new-board .cells .cell .blending-rect {
  border-radius: 2px;
  position: absolute;
}

.new-board .cells .cell .blending-rect.highlight {
  background-color: #ffffff4d;
}

.new-board .cells .cell .blending-rect.shadow {
  background-color: #0000004d;
}

.new-board .player-markers .player-marker {
  border-radius: 50%;
  transition: all 1s;
  animation: 1s infinite marker-horizontal-shaking;
  position: absolute;
}

.new-board .player-markers .player-marker.me {
  animation: 1s ease-in-out infinite floating;
}

.new-board .player-markers .player-marker.entering {
  animation: .3s ease-out slide-in-right;
}

.new-board .player-markers .player-marker.eliminated {
  background-color: #909090;
  border-color: #cbcbcb;
  animation: 1s ease-out .8s forwards simple-fade-out;
}

.new-board .player-markers .player-marker.next {
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.new-board .player-markers .player-marker.next .shape-wrapper {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.new-board .player-markers .player-marker.next.direction-u {
  animation: 1.5s ease-in-out infinite spin-slide-u;
}

.new-board .player-markers .player-marker.next.direction-u .shape-wrapper {
  transform: rotate(-90deg);
}

.new-board .player-markers .player-marker.next.direction-d {
  animation: 1.5s ease-in-out infinite spin-slide-d;
}

.new-board .player-markers .player-marker.next.direction-d .shape-wrapper {
  transform: rotate(90deg);
}

.new-board .player-markers .player-marker.next.direction-l {
  animation: 1.5s ease-in-out infinite spin-slide-l;
}

.new-board .player-markers .player-marker.next.direction-l .shape-wrapper {
  transform: rotate(180deg);
}

.new-board .player-markers .player-marker.next.direction-r {
  animation: 1.5s ease-in-out infinite spin-slide-r;
}

.new-board .player-markers .player-marker .player-marker-inner {
  width: 100%;
  height: 100%;
  padding: 2px;
  position: relative;
  top: 0;
  left: 0;
}

.new-board .player-markers .player-marker .player-marker-inner .marker-border {
  border: 2px solid #000;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.new-board .player-markers .player-marker .player-marker-inner .card-bubble {
  text-align: center;
  z-index: 1;
  opacity: 0;
  background: #fff;
  border-radius: 2px;
  width: 36px;
  padding: 2px 0;
  font-size: 16px;
  font-weight: bold;
  animation: 2s fade-out;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}

.new-board .player-markers .player-marker .player-marker-inner .card-bubble:after {
  content: "";
  border: 6px solid #0000;
  border-top-color: #fff;
  border-bottom: 0;
  width: 0;
  height: 0;
  margin-bottom: -5px;
  margin-left: -6px;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.new-board .board-overlay {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.new-board .outer-border-wrapper {
  z-index: -1;
  pointer-events: none;
  width: 100%;
  height: 100%;
  padding: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.new-board .outer-border-wrapper .outer-border {
  background-color: #000;
  border: 1px solid #b3b3b3;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.new-board .outer-border-wrapper.class1 .outer-border, .new-board .outer-border-wrapper.class2 .outer-border {
  border-width: 3px;
}

.new-board .outer-border-wrapper.class2 .outer-border {
  border-color: #80ffdb;
}

.new-board .outer-border-wrapper .board-glow {
  opacity: 0;
  z-index: -1;
  transition: all .5s;
  position: relative;
}

.new-board .outer-border-wrapper .board-glow:before, .new-board .outer-border-wrapper .board-glow:after {
  content: "";
  aspect-ratio: 1;
  z-index: -1;
  transform-origin: center;
  border-radius: 20%;
  width: 90%;
  animation: 2.5s ease-in-out infinite alternate-reverse glow;
  position: absolute;
  top: 5%;
  left: 5%;
}

.new-board .outer-border-wrapper .board-glow:after {
  border-radius: 40%;
  transform: rotate(45deg);
}

.new-board .outer-border-wrapper.class1 .board-glow {
  opacity: .7;
}

.new-board .outer-border-wrapper.class1 .board-glow:before, .new-board .outer-border-wrapper.class1 .board-glow:after {
  box-shadow: 10px -25px 50px 10px #b3b3b3, 14px -20px 50px 10px #b3b3b3, -20px -25px 50px 10px #b3b3b3, 10px 25px 50px 10px #b3b3b3;
}

.new-board .outer-border-wrapper.class2 .board-glow {
  opacity: 1;
}

.new-board .outer-border-wrapper.class2 .board-glow:before, .new-board .outer-border-wrapper.class2 .board-glow:after {
  box-shadow: 10px -25px 50px 10px #80ffdb, 14px -20px 50px 10px #80ffdb, -20px -25px 50px 10px #80ffdb, 10px 25px 50px 10px #80ffdb;
}

@keyframes glow {
  from {
    aspect-ratio: 1;
  }

  50% {
    aspect-ratio: 1.04;
  }

  to {
    aspect-ratio: .95;
    transform: translate(-4%, 3%);
  }
}

.level-tag {
  color: #fffc;
  background: linear-gradient(#223949 0%, #27273e 100%);
  border: 1px solid #223949;
  border-radius: 2px;
  align-items: baseline;
  gap: 4px;
  padding: 0 3px;
  font-family: Fira Sans;
  line-height: 1.2;
  display: flex;
}

.level-tag .level-prefix {
  font-size: 10px;
  font-weight: 400;
}

.level-tag .level-value {
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
}

.level-tag:not(.small) {
  display: inline-block;
}

.level-tag:not(.small) .level-value {
  text-align: right;
}

.level-tag.small {
  justify-content: center;
  width: 18px;
}

.level-tag.small .level-value {
  text-align: center;
}

button.button, a.button {
  color: #cbcbcb;
  cursor: pointer;
  border: 1px solid #80ffdb4d;
  outline: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: auto;
  min-height: auto;
  padding: 16px 24px;
  font-family: Fira Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  transition: all .3s;
  display: flex;
}

button.button:not(.text-only), a.button:not(.text-only) {
  background: #121212;
  border-radius: 24px;
  box-shadow: -2px -2px 10px #000, 4px 4px 10px #000;
}

button.button:not(.text-only):not(.disabled):hover, button.button:not(.text-only):not(.disabled):focus, a.button:not(.text-only):not(.disabled):hover, a.button:not(.text-only):not(.disabled):focus {
  border: 1px solid #80ffdb;
}

button.button:not(.text-only):active, a.button:not(.text-only):active {
  box-shadow: none;
  color: #909090;
  background: none;
  border: 1px solid #0000;
}

button.button:not(.text-only).disabled, button.button:not(.text-only):disabled, a.button:not(.text-only).disabled, a.button:not(.text-only):disabled {
  color: #ffffff80;
  box-shadow: none;
  cursor: not-allowed;
}

button.button.icon-only, a.button.icon-only {
  color: #80ffdb;
  border: 1px solid #0000;
  border-radius: 50%;
  padding: 16px;
}

button.button.icon-only:not(.accent), a.button.icon-only:not(.accent) {
  box-shadow: none;
  padding: 12px;
}

button.button.icon-only.accent, a.button.icon-only.accent {
  background: #000;
}

button.button.icon-only:hover, button.button.icon-only:focus, a.button.icon-only:hover, a.button.icon-only:focus {
  border: 1px solid #80ffdb;
}

button.button.icon-only:active, a.button.icon-only:active {
  color: #80ffdb4d;
}

button.button.text-only, a.button.text-only {
  box-shadow: none;
  background: none;
  border: 0;
}

button.button.text-only:hover, button.button.text-only:focus, button.button.text-only:active, a.button.text-only:hover, a.button.text-only:focus, a.button.text-only:active {
  color: #cbcbcb;
  text-underline-offset: 6px;
  text-decoration: underline #80ffdb;
}

button.button.text-only:active, a.button.text-only:active {
  color: #909090;
  text-decoration-color: #80ffdb4d;
}

button.button.transparent, a.button.transparent {
  box-shadow: none;
  background: none;
  border: none;
}

a.button {
  text-decoration: none;
}

@keyframes pulse-opacity {
  0% {
    opacity: .3;
  }

  30% {
    opacity: .7;
  }

  70% {
    opacity: .7;
  }

  100% {
    opacity: .3;
  }
}

@keyframes pop {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(50px, 60px);
  }
}

@keyframes simple-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes simple-fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes floating-fading {
  0% {
    opacity: 0;
    bottom: 0%;
  }

  10% {
    opacity: 1;
    bottom: 20%;
  }

  90% {
    opacity: 1;
    bottom: 80%;
  }

  100% {
    opacity: 0;
    bottom: 100%;
  }
}

@keyframes size-smaller {
  0% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulse-scale {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes expand-fade {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  90% {
    opacity: 1;
    transform: scale(1.7);
  }

  100% {
    opacity: 0;
    transform: scale(1.7);
  }
}

@keyframes fade-as-shrink {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  90% {
    opacity: .1;
    transform: scale(.6);
  }

  100% {
    opacity: 0;
    transform: scale(.6);
  }
}

@keyframes slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes my-marker-appear {
  0% {
    opacity: 0;
    transform: scale(20);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes marker-horizontal-shaking {
  0% {
    transform: translateX(0);
  }

  15% {
    transform: translateX(1px);
  }

  30% {
    transform: translateX(-1px);
  }

  45% {
    transform: translateX(1px);
  }

  60% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes floating {
  from {
    transform: translate(0, -1px);
  }

  65% {
    transform: translate(0, 3px);
  }

  to {
    transform: translate(0, -1px);
  }
}

@keyframes appear-spin {
  from {
    opacity: 0;
    transform: rotate(45deg);
  }

  25% {
    transform: rotate(130deg);
  }

  50% {
    opacity: 1;
    transform: rotate(225deg);
  }

  75% {
    opacity: 1;
    transform: rotate(315deg);
  }

  to {
    opacity: .25;
    transform: rotate(405deg);
  }
}

@keyframes spin-slide-l {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  92% {
    opacity: .8;
    transform: translateX(0%);
  }

  100% {
    opacity: 0;
    transform: translateX(0%);
  }
}

@keyframes spin-slide-r {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  92% {
    opacity: .8;
    transform: translateX(0%);
  }

  100% {
    opacity: 0;
    transform: translateX(0%);
  }
}

@keyframes spin-slide-u {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  92% {
    opacity: .8;
    transform: translateY(0%);
  }

  100% {
    opacity: 0;
    transform: translateY(0%);
  }
}

@keyframes spin-slide-d {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  92% {
    opacity: .8;
    transform: translateY(0%);
  }

  100% {
    opacity: 0;
    transform: translateY(0%);
  }
}

.player-list {
  border-right: 1px solid #2d2d2d;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 134px);
  margin-right: auto;
  overflow-y: auto;
}

.player-list:not(.collapsed) {
  max-width: 300px;
}

.player-list.collapsed {
  width: 128px;
}

.player-list.collapsed .level, .player-list.collapsed .name {
  display: none;
}

.player-list .btn-row {
  justify-content: flex-end;
  padding: 4px;
  display: flex;
}

.player-list .row {
  color: #fffc;
  border-bottom: 1px solid #2d2d2d;
  align-items: center;
  gap: 12px;
  height: 35px;
  padding: 0 14px 0 24px;
  font-family: Fira Mono;
  font-size: 14px;
  display: flex;
}

.player-list .row.header {
  font-variant: small-caps;
  letter-spacing: .6px;
  font-family: Fira Sans;
  font-size: 12px;
  font-weight: 400;
}

.player-list .row.target {
  background: #2d2d2d;
  height: 48px;
}

.player-list .row.target .name {
  font-size: 15px;
  font-weight: bold;
}

.player-list .row.dead {
  opacity: .5;
}

.player-list .row.approaching {
  background: #ee24244d;
}

.player-list .row .rank {
  color: #909090;
  text-align: center;
  flex: 0 0 15px;
  font-family: Fira Sans;
  font-weight: 700;
}

.player-list .row .color-block {
  border-radius: 5px;
  flex: 0 0 22px;
  width: 22px;
  height: 22px;
}

.player-list .row:not(.header) .color-block {
  border: 1px solid #000;
}

.player-list .row .name, .player-list .row .point {
  font-weight: 400;
}

.player-list .row .name {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  font-size: 13px;
  overflow: hidden;
}

.player-list .row .point {
  text-align: right;
  flex: 0 0 21px;
}

.player-list.entering .row:not(.header) {
  animation: .3s ease-out slide-in-bottom;
}

.player-tag-wrapper {
  padding: 8px;
  display: inline-block;
}

.player-tag-wrapper.eliminated {
  opacity: .5;
}

.player-tag-wrapper .player-tag {
  font-weight: bold;
}

.player-tag-wrapper.dark {
  color: #fff;
}

.card-deck {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 18px;
  position: relative;
}

.card-deck .card-spot-container {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.card-deck .card-spot-container .card-spot {
  width: 64px;
  height: 80px;
  margin-left: 7px;
  margin-right: 8.5px;
  display: inline-block;
}

.card-deck .card-spot-container .separator {
  background: #b3b3b3;
  width: 1px;
  height: 50px;
  margin: 0 12px;
  display: inline-block;
}

.card-deck .card-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.card-deck .card-container .card {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border: 2px solid #fff0;
  border-radius: 4px;
  width: 64px;
  height: 80px;
  margin-right: 7.5px;
  transition: all .2s;
  display: inline-block;
  position: absolute;
}

.card-deck .card-container .card .card-direction {
  color: #fff;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background: #000;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 18px;
  line-height: 1;
  transition: all .2s;
  position: absolute;
  top: -7.5px;
  right: -7.5px;
}

.card-deck .card-container .card .card-direction.direction-l {
  background-color: #4e4e7c;
}

.card-deck .card-container .card .card-direction.direction-u {
  background-color: #8e3a96;
}

.card-deck .card-container .card .card-direction.direction-d {
  background-color: #447292;
}

.card-deck .card-container .card .card-direction.direction-r {
  background-color: #48b2be;
}

.card-deck .card-container .card .card-direction i:before {
  font-weight: bold !important;
}

.card-deck .card-container .card .card-header {
  justify-content: center;
  height: 30px;
  padding: 6px 2px 0;
  display: flex;
  position: relative;
}

.card-deck .card-container .card .card-header .card-keynum {
  color: #fff;
  z-index: 9;
  -webkit-user-select: none;
  user-select: none;
  background: #5b5b5b;
  border-radius: 2px;
  padding: 1px 2px;
  font-size: 12px;
  position: absolute;
  top: -10px;
  left: -7px;
}

.card-deck .card-container .card .card-header .card-count {
  color: #5b5b5b;
  -webkit-user-select: none;
  user-select: none;
  margin-top: 1px;
  font-size: 12px;
  font-weight: bold;
  display: block;
}

.card-deck .card-container .card .card-header .card-count i {
  padding-right: 1px;
}

.card-deck .card-container .card .card-num {
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  height: 50px;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.2;
  display: block;
}

.card-deck .card-container .card.selected {
  color: #fff;
  transform: translateY(-12px);
}

.card-deck .card-container .card.selected .card-count {
  color: #fff;
}

.card-deck .card-container .card:hover .card-direction, .card-deck .card-container .card.selected .card-direction {
  background: #fff;
  font-weight: bold;
}

.card-deck .card-container .card.direction-l.selected {
  background-color: #4e4e7c;
}

.card-deck .card-container .card.direction-u.selected {
  background-color: #8e3a96;
}

.card-deck .card-container .card.direction-d.selected {
  background-color: #447292;
}

.card-deck .card-container .card.direction-r.selected {
  background-color: #48b2be;
}

.card-deck .card-container .card.direction-l:hover, .card-deck .card-container .card.direction-l.selected {
  border-color: #4e4e7c;
}

.card-deck .card-container .card.direction-l:hover .card-direction, .card-deck .card-container .card.direction-l.selected .card-direction {
  color: #4e4e7c;
  outline: 2px solid #4e4e7c;
}

.card-deck .card-container .card.direction-u:hover, .card-deck .card-container .card.direction-u.selected {
  border-color: #8e3a96;
}

.card-deck .card-container .card.direction-u:hover .card-direction, .card-deck .card-container .card.direction-u.selected .card-direction {
  color: #8e3a96;
  outline: 2px solid #8e3a96;
}

.card-deck .card-container .card.direction-d:hover, .card-deck .card-container .card.direction-d.selected {
  border-color: #447292;
}

.card-deck .card-container .card.direction-d:hover .card-direction, .card-deck .card-container .card.direction-d.selected .card-direction {
  color: #447292;
  outline: 2px solid #447292;
}

.card-deck .card-container .card.direction-r:hover, .card-deck .card-container .card.direction-r.selected {
  border-color: #48b2be;
}

.card-deck .card-container .card.direction-r:hover .card-direction, .card-deck .card-container .card.direction-r.selected .card-direction {
  color: #48b2be;
  outline: 2px solid #48b2be;
}

@media only screen and (width <= 600px) {
  .card-deck .card-container .card {
    width: 42px;
    height: 56px;
  }

  .card-deck .card-container .card .card-direction {
    width: 16px;
    height: 16px;
    font-size: 16px;
  }

  .card-deck .card-container .card .card-header {
    height: 20px;
    padding-top: 4px;
  }

  .card-deck .card-container .card .card-num {
    height: unset;
    font-size: 28px;
  }

  .card-deck .card-container .card:first-child {
    margin-left: 0;
  }

  .card-deck .card-container .card:last-child {
    margin-right: 0;
  }

  .card-deck .card-spot-container .card-spot {
    width: 42px;
    height: 56px;
    margin: 0 2px;
  }

  .card-deck .card-spot-container .card-spot:first-child {
    margin-left: 0;
  }

  .card-deck .card-spot-container .card-spot:last-child {
    margin-right: 0;
  }

  .card-deck .card-spot-container .separator {
    margin: 0 4px;
  }
}

.time-bar {
  width: 100%;
  height: 8px;
  margin-bottom: -8px;
  display: flex;
}

.time-bar .time-beat {
  background: #222;
  flex-basis: 100%;
}

.time-bar .time-beat .time-beat-box {
  background-color: #000;
  width: 100%;
  height: 100%;
}

.time-bar .time-beat.elapsed .time-beat-box {
  width: 0%;
  margin-left: auto;
  transition: width 1s linear;
}

.distribution-chart {
  display: block;
}

.game-stat {
  width: 180px;
  position: relative;
}

.game-stat .display-name {
  text-align: left;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: bold;
}

.game-stat .stat-value {
  color: #ababbf;
  font-size: 12px;
  transition: all .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.game-stat .stat-value.in-range {
  color: #222;
  font-weight: bold;
  transform: scale(1.5);
}

.game-global-status {
  justify-content: space-between;
  display: flex;
}

.game-global-status .status-item {
  letter-spacing: .05rem;
  font-family: Fira Sans;
  font-size: 16px;
}

.game-global-status .status-item .status-item-label {
  text-transform: uppercase;
  color: #fffc;
  padding-right: 4px;
  font-size: 12px;
}

.game-global-status .status-item .status-item-value {
  color: #fff;
  font-weight: bold;
}

.game-missions {
  color: #fffc;
  letter-spacing: .8px;
  max-width: 100%;
  container: mission / inline-size;
}

.game-missions .missions {
  max-width: 350px;
  padding-bottom: 4px;
}

@container mission (width <= 250px) {
  .game-missions .game-mission .mission-stat .stat {
    width: 50px;
  }
}

@container mission (width <= 200px) {
  .game-missions .game-mission {
    flex-direction: column;
    align-items: flex-start;
  }

  .game-missions .game-mission .mission-body {
    border-bottom: 1px solid #2d2d2d;
    border-right: none;
  }

  .game-missions .game-mission .mission-stat {
    text-align: left;
    padding-top: 6px;
  }

  .game-missions .game-mission .mission-stat .stat {
    text-align: left;
    padding: 0;
  }
}

.game-mission {
  color: #fff;
  align-items: center;
  font-family: Fira Sans;
  font-size: 14px;
  display: flex;
}

.game-mission .mission-body {
  border-right: 1px solid #2d2d2d;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 8px;
  font-weight: 300;
}

.game-mission .mission-stat {
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 8px;
  font-weight: bolder;
}

.game-mission .mission-stat .stat {
  text-align: right;
  width: 75px;
  padding: 0 4px;
  display: inline-block;
}

.game-mission .mission-stat i:before {
  transition: all .7s;
}

.game-mission .mission-stat i.steady:before {
  transform: rotate(0)translateY(0);
}

.game-mission .mission-stat i.increase:before {
  transform: rotate(-45deg)translateY(-4px);
}

.game-mission .mission-stat i.decrease:before {
  transform: rotate(45deg)translateY(4px);
}

.game-mission.accomplished {
  border-left: 4px solid #80ffdb;
}

.game-mission.accomplished .mission-stat .stat {
  color: #fff;
}

.game-mission.not-accomplished {
  border-left: 4px solid #ee24244d;
}

.game-mission.not-accomplished .mission-stat .stat {
  color: #ff6969;
}

.game-mission.final {
  color: #fffc;
}

.game-mission.final.accomplished {
  background-color: #80ffdb4d;
}

.game-mission.final.not-accomplished {
  background-color: #ee24244d;
}

.game-mission:not(.final) {
  background-color: #000;
}

.game-mission:not(:last-child) {
  margin-bottom: 12px;
}

.game-status-msg {
  text-align: center;
  letter-spacing: .16px;
  color: #fffc;
  white-space: pre-wrap;
  margin: 0 auto;
  font-family: Fira Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
  transition: all .3s;
  overflow: hidden;
}

.game-status-msg.popup {
  background-color: #2d2d2d99;
  width: 100%;
  height: 100px;
  font-size: 24px;
  line-height: 100px;
  position: fixed;
  top: calc(50% - 50px);
  left: 0;
}

.game-status-msg .message .player-names {
  font-weight: 700;
}

.game-status-msg .message.status-message {
  animation: 2s infinite pulse-opacity;
}

.game-status-msg.event .message {
  color: #80ffdb;
  font-weight: bold;
  animation: .5s ease-in forwards size-smaller;
}

.game-status-msg.alert .message {
  color: #ff6969;
  animation: 1s ease-out infinite pulse-scale;
}

.knockout-bonus {
  color: #fffc;
  letter-spacing: .8px;
  width: 220px;
  max-width: 100%;
  font-family: fira sans;
  font-size: 10px;
}

.knockout-bonus .title {
  margin-bottom: 8px !important;
}

.knockout-bonus .title img {
  width: 21px;
}

.knockout-bonus .knockout-tones .knockout-bonus-tone {
  text-align: center;
  color: #000;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #fff;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  margin: 4px;
  font-weight: bold;
  line-height: 16px;
  animation: .5s forwards slide-in-bottom;
  display: inline-block;
}

.knockout-bonus .knockout-tones .knockout-bonus-tone.dark {
  color: #fff;
}

.tile {
  backdrop-filter: blur(3px);
  background: #00000059;
  border: 1px solid #2d2d2d;
  border-radius: 2px;
  width: fit-content;
}

.game-reward {
  color: #fffc;
  letter-spacing: .8px;
  max-width: 100%;
  font-family: fira sans;
  font-size: 14px;
  position: relative;
}

.game-reward .title {
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.3;
}

.game-reward .row {
  text-align: right;
  margin-bottom: 12px;
}

.game-reward .row img {
  width: 20px;
}

.game-reward .row .label {
  vertical-align: bottom;
  text-wrap: nowrap;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 300;
}

.game-reward .row .value {
  color: #fff;
  vertical-align: bottom;
  font-size: 16px;
  font-weight: 700;
}

.game-reward .row .value.class-0 {
  color: #ffffff80;
}

.game-reward .row.my-share {
  border-bottom: 1px solid #909090;
  padding-bottom: 16px;
}

.game-reward .row.tones .expected-tones {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.game-reward .row.tones .expected-tones.class-0 {
  color: #ffffff80;
}

.game-reward .row.tones .label {
  margin-right: 0;
  font-size: 14px;
  line-height: 1.5;
}

.game-reward .row:last-child {
  margin-bottom: 0;
}

.game-reward .explanation-box {
  position: absolute;
  top: 46px;
  right: -110px;
}

.game-reward .explanation-box .explanation-inner {
  padding: 8px;
}

.game-reward .explanation-box .explanation-inner:before {
  content: " ";
  border: 7px solid #111;
  border-color: #0000 #111 #111 #0000;
  width: 0;
  height: 0;
  position: absolute;
  inset: 62px auto auto -14px;
}

.game-reward .explanation-box .explanation-inner:after {
  content: " ";
  border: 7px solid #2d2d2d;
  border-color: #2d2d2d #2d2d2d #0000 #0000;
  width: 0;
  height: 0;
  position: absolute;
  inset: 75px auto auto -14px;
}

.game-reward .explanation-box:before {
  content: " ";
  border: 7px solid #2d2d2d;
  border-color: #0000 #2d2d2d #2d2d2d #0000;
  width: 0;
  height: 0;
  position: absolute;
  inset: 62px auto auto -14px;
}

.game-reward .explanation-box:after {
  content: " ";
  border: 7px solid #111;
  border-color: #111 #111 #0000 #0000;
  width: 0;
  height: 0;
  position: absolute;
  inset: 75px auto auto -13px;
}

.game-reward .flag i {
  padding-left: 4px;
}

.confirmation {
  z-index: 99;
  border-radius: 1px;
  padding: 16px;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.confirmation .confirmation-inner {
  width: 100%;
  max-width: 400px;
  height: 100%;
  padding: 32px;
}

.confirmation .confirmation-inner .confirmation-message {
  color: #fffc;
}

.confirmation .confirmation-inner .confirmation-actions {
  justify-content: space-around;
  padding-top: 24px;
  display: flex;
}

.confirmation-backdrop {
  z-index: 1;
  background: #1119;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.gameplay {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.gameplay ::-webkit-scrollbar {
  background-color: #0000;
  width: 12px;
}

.gameplay ::-webkit-scrollbar-thumb {
  background: #5b5b5b padding-box padding-box;
  border-left: 2px solid #0000;
  border-right: 2px solid #0000;
  border-radius: 4px;
}

.gameplay .content-area {
  flex: 1;
  justify-content: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.gameplay .content-area .sidebar-left-wrapper {
  height: 100%;
  padding-right: 32px;
}

.gameplay .content-area .sidebar-left-wrapper > * {
  height: 100%;
}

.gameplay .content-area .game {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.gameplay .content-area .game .game-info {
  flex-direction: column;
  justify-content: end;
  padding: 0 12px;
  display: flex;
}

.gameplay .content-area .game .player-info {
  text-align: center;
  z-index: 0;
}

.gameplay .content-area .game .player-info .game-status-msg {
  margin-top: 12px;
}

.gameplay .content-area .sidebar-right-wrapper {
  height: 100%;
  padding-left: 32px;
  display: grid;
  overflow-y: auto;
}

.gameplay .content-area .sidebar-right-wrapper .sidebar-right {
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  row-gap: 16px;
  padding-right: 16px;
  display: flex;
}

.gameplay .content-area .sidebar-right-wrapper .sidebar-right .title {
  text-transform: uppercase;
  color: #fffc;
  letter-spacing: .75px;
  margin-bottom: 16px;
  font-family: fira sans;
  font-size: 16px;
  line-height: 1.3;
}

.gameplay .content-area .sidebar-right-wrapper .mission-gauges .title .game-class {
  font-weight: bold;
}

.gameplay .content-area .sidebar-right-wrapper .mission-gauges .gauges > :not(:last-child) {
  margin-bottom: 24px;
}

.gameplay .content-area .sidebar-right-wrapper .mission-gauges .mission-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.gameplay .content-area .sidebar-right-wrapper .mission-gauges .mission-backdrop > div {
  background: #0009;
  position: absolute;
}

.gameplay .control-area {
  background: #000;
  flex-grow: 0;
  width: 100%;
  height: 134px;
}

.gameplay .control-area #eliminate-message {
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  height: 100%;
  display: flex;
}

.gameplay .control-area #eliminate-message span {
  color: #fffc;
  font-weight: bold;
}

.lockable.locked {
  opacity: .7;
}

.icon-menu {
  vertical-align: middle;
  display: inline-block;
}

.icon-menu > :not(:first-child) {
  margin-left: 16px;
}

.icon-menu .icon-menu-button {
  color: #333;
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 4px solid #0000;
  padding-bottom: 4px;
  font-size: 16px;
  transition: border-color .2s;
  display: inline-block;
}

.icon-menu .icon-menu-button:hover {
  border-color: #555;
}

.top-nav-left {
  z-index: 99;
  position: fixed;
  top: 12px;
  left: 16px;
}

.top-nav-left img {
  cursor: pointer;
  height: 26px;
}

.top-nav-right {
  z-index: 99;
  display: flex;
  position: fixed;
  top: 0;
  right: 16px;
}

.top-nav-right .icon-menu {
  padding: 4px;
}

.top-nav-right .exception-message {
  color: #eee2;
  font-size: 12px;
}

.top-nav-right .logout-popup {
  text-align: center;
  color: #fff;
  width: max-content;
  position: absolute;
  top: 52px;
  right: 6px;
}

.top-nav-right .logout-popup .logout-popup-content {
  padding: 24px 64px 16px;
}

.top-nav-right .logout-popup .message {
  margin-bottom: 16px;
  font-family: Fira Sans;
  font-size: 14px;
}

.top-nav-right .logout-popup .button-row button {
  display: inline-block;
}

.top-nav-right .logout-popup .button-row button:not(:last-child) {
  margin-right: 16px;
}

.board-with-status {
  position: relative;
}

.board-with-status .flex-row {
  justify-content: center;
  display: flex;
}

@media screen and (width <= 1200px) {
  .board-with-status .flex-row {
    padding-right: 200px;
  }
}

.board-with-status .players {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.board-with-status .players.players-left {
  text-align: right;
  flex-wrap: wrap-reverse;
  padding-right: 24px;
}

.board-with-status .players.players-left .player-tag {
  margin-left: 21px;
}

.board-with-status .players.players-right {
  flex-wrap: wrap;
  padding-left: 24px;
}

.board-with-status .players.players-right .player-tag {
  margin-right: 21px;
}

.board-with-status .players .player-tag {
  opacity: 1;
  flex: 0 64px;
  height: 64px;
  padding-top: 14px;
  transition: opacity 1s;
  position: relative;
}

.board-with-status .players .player-tag .player-flag {
  vertical-align: middle;
  text-align: center;
  color: #2229;
  border: 2px solid #7774;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  font-weight: bold;
  line-height: 32px;
  display: inline-block;
}

.board-with-status .players .player-tag.dark .player-flag {
  color: #fffd;
}

.board-with-status .players .player-tag:not(.eliminated) .player-flag {
  box-shadow: 0 0 #c8c8c8;
}

.board-with-status .players .player-tag.eliminated {
  opacity: .05;
}

.board-with-status .players .player-tag .level-tag {
  position: absolute;
  top: 4px;
  right: -8px;
}

.board-with-status .board {
  background-color: #0000;
  border: 1px solid #909090;
  border-radius: 5px;
}

.board-with-status .canvas-overlay {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.board-with-status .canvas-overlay .player-marker {
  border-radius: 50%;
  transition: all .7s ease-in-out;
  position: absolute;
  box-shadow: 0 0 2px #00000080;
}

.board-with-status .canvas-overlay .player-marker .player-marker-inner {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.board-with-status .canvas-overlay .player-marker .player-marker-inner .card-bubble {
  text-align: center;
  z-index: 1;
  opacity: 0;
  background: #fff;
  border-radius: 2px;
  width: 36px;
  padding: 2px 0;
  font-size: 16px;
  font-weight: bold;
  animation: 2s fade-out;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}

.board-with-status .canvas-overlay .player-marker .player-marker-inner .card-bubble:after {
  content: "";
  border: 6px solid #0000;
  border-top-color: #fff;
  border-bottom: 0;
  width: 0;
  height: 0;
  margin-bottom: -5px;
  margin-left: -6px;
  position: absolute;
  bottom: 0;
  left: 50%;
}

@keyframes pulse {
  0% {
    transform: scale(.92);
    box-shadow: 0 0 #fcb4b3ef;
  }

  40% {
    transform: scale(1);
    box-shadow: 0 0 0 8px #f2848200;
  }

  100% {
    transform: scale(.92);
    box-shadow: 0 0 #f2848200;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.chart-wrapper {
  width: 100%;
}

.chart-wrapper .title-wrapper text {
  dominant-baseline: hanging;
  font-family: Fira Sans;
}

.chart-wrapper .title-wrapper .chart-title {
  font-size: 12px;
}

.chart-wrapper .title-wrapper .chart-values {
  font-size: 14px;
  font-weight: 300;
}

.chart-wrapper .title-wrapper .pie-value {
  font-size: 36px;
  font-weight: 500;
}

.chart-wrapper .chart-bar .chart-values {
  text-anchor: end;
}

.chart-wrapper .chart-donut .title-wrapper {
  text-anchor: middle;
}

#tone-stack {
  z-index: 0;
  flex-flow: row-reverse wrap-reverse;
  justify-content: flex-start;
  gap: 0;
  width: 100%;
  padding: 24px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

#tone-stack .tone-stack-block-wrapper {
  padding: 6px;
}

#tone-stack .tone-stack-block, #tone-stack .tone-stack-reserved-spot {
  opacity: .5;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  transition: all .2s;
  animation: 1s linear appear-spin;
  transform: rotate(45deg);
}

#tone-stack .tone-stack-block-wrapper:hover .tone-stack-block, #tone-stack .tone-stack-block-wrapper:hover .tone-stack-reserved-spot {
  opacity: 1;
  transform: rotate(360deg)scale(2);
}

#result {
  z-index: 9;
  width: 100%;
  height: 100%;
  padding: 40px;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

#result .game-class-splash {
  z-index: 1;
  pointer-events: none;
  background: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#result .game-class-splash .splash-content {
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#result .game-class-splash .message {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #020024 0%, #090979 35%, #00d4ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 128px;
}

#result .result-content {
  z-index: 1;
  text-align: center;
  border-radius: 3px;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

#result .result-content .result-row .title {
  text-transform: uppercase;
  color: #eaeaea;
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
  font-family: Fira Sans;
  font-size: 21px;
  font-weight: 300;
  position: relative;
}

#result .result-content .result-row .title:after {
  content: "";
  background: #80ffdb;
  width: 176px;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 88px);
}

#result .result-content .result-row .title i {
  vertical-align: middle;
  font-size: 12px;
}

#result .result-content .result-row:not(:last-child) {
  margin-bottom: 90px;
}

#result .result-content .result-row.winner {
  text-align: center;
  font-family: Fira Sans;
}

#result .result-content .result-row.winner img {
  z-index: 999;
  margin: 0 auto -16px;
  display: block;
  position: relative;
}

#result .result-content .result-row.winner .text {
  color: #cbcbcb;
  margin: 16px 0 130px;
  font-size: 16px;
  font-weight: 400;
}

#result .result-content .result-row.winner .name {
  color: #fff;
  text-shadow: 0 0 4px #00000080;
  margin-top: 8px;
  padding: 8px 16px;
  font-family: Fira Mono;
  font-size: 32px;
  font-weight: bold;
  display: inline-block;
}

#result .result-content .result-row.winner .message {
  margin-top: 24px;
}

#result .result-content .result-row.winner .message .ranking {
  font-weight: bold;
}

#result .result-content .result-row.actions {
  margin-top: -32px;
}

#result .result-content .result-row.actions button {
  display: inline-block;
}

#result .result-content .result-row.actions button:not(:last-child) {
  margin-right: 36px;
}

#result .result-content .result-sections {
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 100px;
  padding-top: 36px;
  display: flex;
}

#result .result-content .ranking {
  flex: 0 600px;
}

#result .result-content .ranking .rewards {
  color: #eaeaea;
  border: 1px solid #2d2d2d;
  border-radius: 2px;
  margin-bottom: 48px;
}

#result .result-content .ranking .rewards .player-profile-bar {
  background-color: #2d2d2d;
  justify-content: space-between;
  padding: 16px;
  display: flex;
}

#result .result-content .ranking .rewards .player-profile-bar .player-profile {
  vertical-align: middle;
  font-size: 11px;
}

#result .result-content .ranking .rewards .player-profile-bar .player-profile > * {
  vertical-align: middle;
  display: inline-block;
}

#result .result-content .ranking .rewards .player-profile-bar .player-profile .chart-wrapper {
  width: fit-content;
}

#result .result-content .ranking .rewards .player-profile-bar .player-profile .level-tag, #result .result-content .ranking .rewards .player-profile-bar .player-profile .chart-wrapper {
  margin-right: 12px;
}

#result .result-content .ranking .rewards .player-profile-bar .player-profile .next {
  color: #fff;
  margin-right: 6px;
  font-weight: bold;
}

#result .result-content .ranking .rewards .player-profile-bar .player-profile .level-up-message {
  color: #80ffdb;
  font-size: 14px;
  animation: 1s ease-in-out infinite floating;
}

#result .result-content .ranking table {
  border-spacing: 0;
  width: 100%;
}

#result .result-content .ranking table tr {
  box-shadow: inset 0 -1px #2d2d2d;
}

#result .result-content .ranking table thead th {
  text-align: right;
  color: #fffc;
  text-transform: uppercase;
  font-family: Fira Sans;
  font-weight: 400;
}

#result .result-content .ranking table thead th.left {
  text-align: left;
}

#result .result-content .ranking table thead tr th {
  padding: 8px 4px;
  font-size: 12px;
}

#result .result-content .ranking table thead tr th:first-child {
  padding-left: 0;
}

#result .result-content .ranking table thead tr th:last-child {
  padding-right: 4px;
}

#result .result-content .ranking table tbody tr td {
  color: #fff;
  text-align: right;
  height: 40px;
  padding: 4px;
  font-family: Fira Mono;
  font-size: 14px;
  font-weight: 400;
}

#result .result-content .ranking table tbody tr td .marker {
  vertical-align: middle;
  border: 1px solid #fff;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  display: inline-block;
}

#result .result-content .ranking table tbody tr td:first-child {
  padding-left: 0;
}

#result .result-content .ranking table tbody tr td:last-child {
  padding-right: 10px;
}

#result .result-content .ranking table tbody tr.my-row {
  background: #000;
}

#result .result-content .ranking table tbody tr.my-row td {
  font-weight: bold;
}

#result .result-content .ranking table tbody td.col-ranking {
  color: #5b5b5b;
  text-align: center;
  text-align: right;
  font-family: Fira Mono;
  font-size: 24px;
  font-weight: bold;
}

#result .result-content .ranking table tbody td.col-player-tag {
  text-align: left;
}

#result .result-content .ranking table tbody td.col-player-tag span.level-up {
  color: #80ffdb;
  vertical-align: top;
  margin-left: 6px;
  font-family: Fira Mono;
  font-size: 10px;
  font-weight: bold;
}

#result .result-content .result-wrapper {
  padding: 32px;
}

#result .result-content .board-wrapper .board {
  border-radius: 2px;
  width: 300px;
  height: 300px;
}

#result .result-content .board-wrapper .game-class {
  box-sizing: border-box;
  backdrop-filter: blur(1px);
  text-transform: uppercase;
  color: #fff;
  background: #0009;
  border: 1px solid #000;
  border-radius: 2px;
  width: fit-content;
  margin: 0 auto -111px;
  padding: 32px 24px 22px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  position: relative;
  top: -206px;
}

#result .result-content .board-wrapper .game-class img {
  width: 36px;
  position: absolute;
  top: -15px;
  left: calc(50% - 18px);
}

#result .result-content .board-wrapper .game-class .smaller {
  text-transform: none;
  font-size: 12px;
  font-weight: 400;
}

#result .result-content .board-wrapper .divider {
  width: 1px;
  height: 24px;
}

#result .result-content .board-wrapper .stats {
  text-align: left;
  flex-wrap: wrap;
  gap: 16px 26px;
  width: 364px;
  display: flex;
  position: relative;
}

#result .result-content .board-wrapper .stats .stat {
  text-align: center;
  color: #fff;
  background: #2d2d2d;
  border-radius: 8px;
  flex-direction: column;
  flex: 0 calc(33% - 16px);
  margin-bottom: 4px;
  padding: 12px;
  display: flex;
  position: relative;
}

#result .result-content .board-wrapper .stats .stat .stat-display-name {
  text-transform: uppercase;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

#result .result-content .board-wrapper .stats .stat .stat-display-name .lStat {
  text-align: center;
  vertical-align: middle;
  padding-right: 2px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline dotted;
  display: inline-block;
  position: relative;
}

#result .result-content .board-wrapper .stats .stat .stat-display-name .lStat:hover .tooltip {
  font-size: 10px;
  font-weight: 400;
  display: block;
}

#result .result-content .board-wrapper .stats .stat .stat-display-name .lStat .tooltip {
  color: #fff;
  white-space: nowrap;
  background: #fff;
  padding: 6px 8px;
  display: none;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

#result .result-content .board-wrapper .stats .stat .stat-display-name .lStat .tooltip:after {
  content: "";
  border-top: 8px solid #fff;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  position: absolute;
  bottom: -8px;
  left: calc(50% - 9px);
}

#result .result-content .board-wrapper .stats .stat .stat-value {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
}

#result .result-content .board-wrapper .stats .stat.objective.satisfied {
  color: #fff;
  background: #eaeaea;
  border: 1px solid #fff;
}

#result .result-content .rewards .type {
  vertical-align: top;
  text-transform: uppercase;
  margin-right: 12px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
}

#result .result-content .rewards .tones-link {
  color: inherit;
  font-size: 12px;
}

#result .import-tone-stack {
  color: #fff;
  background-color: #121212f4;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#result .import-tone-stack .received-tones {
  text-align: center;
  z-index: 9;
  border: 1px solid #2d2d2d;
  border-radius: 2px;
  width: 600px;
  max-width: 100%;
  max-height: calc(100% - 50px);
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
}

#result .import-tone-stack .received-tones .actions .button {
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
}

#result .received-tones {
  background-color: #00000059;
  padding: 24px 0 48px;
}

#result .received-tones img {
  margin-bottom: 32px;
}

#result .received-tones .message {
  margin-bottom: 12px;
  font-family: Fira Sans;
  font-size: 24px;
  font-weight: 300;
}

#result .received-tones .message .reason {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: bold;
}

#result .received-tones .message .tone-number {
  color: #80ffdb;
  font-weight: bold;
}

#result .received-tones .reward-tones {
  vertical-align: top;
  text-align: left;
  max-width: 300px;
  display: inline-block;
}

#result .received-tones .reward-tones .reward-tone, #result .received-tones .reward-tones .reward-tone-placeholder {
  border-radius: 2px;
  width: 16px;
  height: 16px;
  margin: 4px;
  display: inline-block;
}

#result .received-tones .reward-tones .reward-tone {
  border: 1px solid #fff;
}

#result .confetti-layer {
  pointer-events: none;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#result .confetti-layer > div {
  position: absolute;
}

#result .confetti-0 {
  opacity: .735867;
  background-color: #263672;
  width: 3px;
  height: 1.2px;
  animation: 4.72044s .403902s infinite drop-0;
  top: -10%;
  left: 80%;
  transform: rotate(68.893deg);
}

@keyframes drop-0 {
  100% {
    top: 110%;
    left: 85%;
  }
}

#result .confetti-1 {
  opacity: 1.26293;
  background-color: #d13447;
  width: 8px;
  height: 3.2px;
  animation: 4.69572s .383686s infinite drop-1;
  top: -10%;
  left: 31%;
  transform: rotate(195.627deg);
}

@keyframes drop-1 {
  100% {
    top: 110%;
    left: 35%;
  }
}

#result .confetti-2 {
  opacity: .851711;
  background-color: #263672;
  width: 2px;
  height: .8px;
  animation: 4.53657s .918011s infinite drop-2;
  top: -10%;
  left: 70%;
  transform: rotate(265.418deg);
}

@keyframes drop-2 {
  100% {
    top: 110%;
    left: 79%;
  }
}

#result .confetti-3 {
  opacity: 1.37491;
  background-color: #ffbf00;
  width: 5px;
  height: 2px;
  animation: 4.14752s .175155s infinite drop-3;
  top: -10%;
  left: 31%;
  transform: rotate(352.866deg);
}

@keyframes drop-3 {
  100% {
    top: 110%;
    left: 46%;
  }
}

#result .confetti-4 {
  opacity: .755171;
  background-color: #d13447;
  width: 4px;
  height: 1.6px;
  animation: 4.54071s .379067s infinite drop-4;
  top: -10%;
  left: 23%;
  transform: rotate(22.8745deg);
}

@keyframes drop-4 {
  100% {
    top: 110%;
    left: 24%;
  }
}

#result .confetti-5 {
  opacity: 1.04386;
  background-color: #ffbf00;
  width: 4px;
  height: 1.6px;
  animation: 4.47196s .104861s infinite drop-5;
  top: -10%;
  left: 6%;
  transform: rotate(177.951deg);
}

@keyframes drop-5 {
  100% {
    top: 110%;
    left: 16%;
  }
}

#result .confetti-6 {
  opacity: 1.29614;
  background-color: #ffbf00;
  width: 6px;
  height: 2.4px;
  animation: 4.69239s 34.008ms infinite drop-6;
  top: -10%;
  left: 30%;
  transform: rotate(86.323deg);
}

@keyframes drop-6 {
  100% {
    top: 110%;
    left: 43%;
  }
}

#result .confetti-7 {
  opacity: .627057;
  background-color: #d13447;
  width: 8px;
  height: 3.2px;
  animation: 4.8999s .10939s infinite drop-7;
  top: -10%;
  left: 83%;
  transform: rotate(253.852deg);
}

@keyframes drop-7 {
  100% {
    top: 110%;
    left: 91%;
  }
}

#result .confetti-8 {
  opacity: 1.3128;
  background-color: #263672;
  width: 1px;
  height: .4px;
  animation: 4.36625s .133118s infinite drop-8;
  top: -10%;
  left: 80%;
  transform: rotate(75.3247deg);
}

@keyframes drop-8 {
  100% {
    top: 110%;
    left: 95%;
  }
}

#result .confetti-9 {
  opacity: 1.24184;
  background-color: #ffbf00;
  width: 6px;
  height: 2.4px;
  animation: 4.29812s .839353s infinite drop-9;
  top: -10%;
  left: 7%;
  transform: rotate(103.982deg);
}

@keyframes drop-9 {
  100% {
    top: 110%;
    left: 15%;
  }
}

#result .confetti-10 {
  opacity: .566409;
  background-color: #d13447;
  width: 2px;
  height: .8px;
  animation: 4.55707s .790231s infinite drop-10;
  top: -10%;
  left: 13%;
  transform: rotate(316.089deg);
}

@keyframes drop-10 {
  100% {
    top: 110%;
    left: 21%;
  }
}

#result .confetti-11 {
  opacity: .916414;
  background-color: #d13447;
  width: 7px;
  height: 2.8px;
  animation: 4.29414s .886641s infinite drop-11;
  top: -10%;
  left: 72%;
  transform: rotate(297.564deg);
}

@keyframes drop-11 {
  100% {
    top: 110%;
    left: 73%;
  }
}

#result .confetti-12 {
  opacity: 1.16234;
  background-color: #ffbf00;
  width: 4px;
  height: 1.6px;
  animation: 4.64474s .460264s infinite drop-12;
  top: -10%;
  left: 84%;
  transform: rotate(26.6736deg);
}

@keyframes drop-12 {
  100% {
    top: 110%;
    left: 90%;
  }
}

#result .confetti-13 {
  opacity: 1.02248;
  background-color: #263672;
  width: 8px;
  height: 3.2px;
  animation: 4.05124s .697118s infinite drop-13;
  top: -10%;
  left: 87%;
  transform: rotate(242.278deg);
}

@keyframes drop-13 {
  100% {
    top: 110%;
    left: 101%;
  }
}

#result .confetti-14 {
  opacity: .513257;
  background-color: #ffbf00;
  width: 1px;
  height: .4px;
  animation: 4.45067s .282338s infinite drop-14;
  top: -10%;
  left: 22%;
  transform: rotate(358.996deg);
}

@keyframes drop-14 {
  100% {
    top: 110%;
    left: 23%;
  }
}

#result .confetti-15 {
  opacity: .884829;
  background-color: #263672;
  width: 1px;
  height: .4px;
  animation: 4.76006s .341218s infinite drop-15;
  top: -10%;
  left: 25%;
  transform: rotate(111.024deg);
}

@keyframes drop-15 {
  100% {
    top: 110%;
    left: 37%;
  }
}

#result .confetti-16 {
  opacity: .683466;
  background-color: #263672;
  width: 7px;
  height: 2.8px;
  animation: 4.96748s .808813s infinite drop-16;
  top: -10%;
  left: 80%;
  transform: rotate(155.571deg);
}

@keyframes drop-16 {
  100% {
    top: 110%;
    left: 87%;
  }
}

#result .confetti-17 {
  opacity: .716809;
  background-color: #263672;
  width: 2px;
  height: .8px;
  animation: 4.68478s .34032s infinite drop-17;
  top: -10%;
  left: 42%;
  transform: rotate(295.266deg);
}

@keyframes drop-17 {
  100% {
    top: 110%;
    left: 51%;
  }
}

#result .confetti-18 {
  opacity: 1.13067;
  background-color: #263672;
  width: 6px;
  height: 2.4px;
  animation: 4.93267s .319059s infinite drop-18;
  top: -10%;
  left: 44%;
  transform: rotate(206.086deg);
}

@keyframes drop-18 {
  100% {
    top: 110%;
    left: 46%;
  }
}

#result .confetti-19 {
  opacity: .981323;
  background-color: #ffbf00;
  width: 2px;
  height: .8px;
  animation: 4.2687s .583417s infinite drop-19;
  top: -10%;
  left: 53%;
  transform: rotate(326.624deg);
}

@keyframes drop-19 {
  100% {
    top: 110%;
    left: 54%;
  }
}

#result .confetti-20 {
  opacity: 1.30674;
  background-color: #263672;
  width: 4px;
  height: 1.6px;
  animation: 4.94008s 27.8714ms infinite drop-20;
  top: -10%;
  left: 76%;
  transform: rotate(316.084deg);
}

@keyframes drop-20 {
  100% {
    top: 110%;
    left: 81%;
  }
}

#result .confetti-21 {
  opacity: 1.22171;
  background-color: #263672;
  width: 8px;
  height: 3.2px;
  animation: 4.13456s .543801s infinite drop-21;
  top: -10%;
  left: 70%;
  transform: rotate(71.5059deg);
}

@keyframes drop-21 {
  100% {
    top: 110%;
    left: 82%;
  }
}

#result .confetti-22 {
  opacity: 1.43286;
  background-color: #263672;
  width: 5px;
  height: 2px;
  animation: 4.26157s .877881s infinite drop-22;
  top: -10%;
  left: 43%;
  transform: rotate(262.651deg);
}

@keyframes drop-22 {
  100% {
    top: 110%;
    left: 48%;
  }
}

#result .confetti-23 {
  opacity: 1.39891;
  background-color: #263672;
  width: 6px;
  height: 2.4px;
  animation: 4.01395s 46.1856ms infinite drop-23;
  top: -10%;
  left: 82%;
  transform: rotate(324.926deg);
}

@keyframes drop-23 {
  100% {
    top: 110%;
    left: 85%;
  }
}

#result .confetti-24 {
  opacity: .747577;
  background-color: #d13447;
  width: 5px;
  height: 2px;
  animation: 4.19718s .482113s infinite drop-24;
  top: -10%;
  left: 91%;
  transform: rotate(122.285deg);
}

@keyframes drop-24 {
  100% {
    top: 110%;
    left: 102%;
  }
}

#result .confetti-25 {
  opacity: 1.49142;
  background-color: #263672;
  width: 2px;
  height: .8px;
  animation: 4.0479s .378618s infinite drop-25;
  top: -10%;
  left: 67%;
  transform: rotate(348.36deg);
}

@keyframes drop-25 {
  100% {
    top: 110%;
    left: 77%;
  }
}

#result .confetti-26 {
  opacity: .703055;
  background-color: #263672;
  width: 1px;
  height: .4px;
  animation: 4.17002s .68736s infinite drop-26;
  top: -10%;
  left: 100%;
  transform: rotate(296.849deg);
}

@keyframes drop-26 {
  100% {
    top: 110%;
    left: 103%;
  }
}

#result .confetti-27 {
  opacity: 1.22919;
  background-color: #ffbf00;
  width: 3px;
  height: 1.2px;
  animation: 4.97926s .41145s infinite drop-27;
  top: -10%;
  left: 62%;
  transform: rotate(262.513deg);
}

@keyframes drop-27 {
  100% {
    top: 110%;
    left: 68%;
  }
}

#result .confetti-28 {
  opacity: .502164;
  background-color: #d13447;
  width: 4px;
  height: 1.6px;
  animation: 4.02938s .73068s infinite drop-28;
  top: -10%;
  left: 26%;
  transform: rotate(149.599deg);
}

@keyframes drop-28 {
  100% {
    top: 110%;
    left: 31%;
  }
}

#result .confetti-29 {
  opacity: 1.29797;
  background-color: #263672;
  width: 5px;
  height: 2px;
  animation: 4.8822s .585591s infinite drop-29;
  top: -10%;
  left: 70%;
  transform: rotate(221.808deg);
}

@keyframes drop-29 {
  100% {
    top: 110%;
    left: 79%;
  }
}

#result .confetti-30 {
  opacity: 1.00959;
  background-color: #d13447;
  width: 4px;
  height: 1.6px;
  animation: 4.70211s 5.90602ms infinite drop-30;
  top: -10%;
  left: 2%;
  transform: rotate(353.474deg);
}

@keyframes drop-30 {
  100% {
    top: 110%;
    left: 6%;
  }
}

#result .confetti-31 {
  opacity: .929797;
  background-color: #ffbf00;
  width: 1px;
  height: .4px;
  animation: 4.0729s .815536s infinite drop-31;
  top: -10%;
  left: 74%;
  transform: rotate(269.779deg);
}

@keyframes drop-31 {
  100% {
    top: 110%;
    left: 82%;
  }
}

#result .confetti-32 {
  opacity: .669501;
  background-color: #ffbf00;
  width: 5px;
  height: 2px;
  animation: 4.29514s .496729s infinite drop-32;
  top: -10%;
  left: 32%;
  transform: rotate(148.179deg);
}

@keyframes drop-32 {
  100% {
    top: 110%;
    left: 33%;
  }
}

#result .confetti-33 {
  opacity: .57316;
  background-color: #263672;
  width: 6px;
  height: 2.4px;
  animation: 4.55529s .3938s infinite drop-33;
  top: -10%;
  left: 37%;
  transform: rotate(15.8185deg);
}

@keyframes drop-33 {
  100% {
    top: 110%;
    left: 44%;
  }
}

#result .confetti-34 {
  opacity: .99745;
  background-color: #d13447;
  width: 4px;
  height: 1.6px;
  animation: 4.63761s 15.0606ms infinite drop-34;
  top: -10%;
  left: 55%;
  transform: rotate(.184985deg);
}

@keyframes drop-34 {
  100% {
    top: 110%;
    left: 60%;
  }
}

#result .confetti-35 {
  opacity: 1.02963;
  background-color: #ffbf00;
  width: 1px;
  height: .4px;
  animation: 4.51827s .915167s infinite drop-35;
  top: -10%;
  left: 93%;
  transform: rotate(183.625deg);
}

@keyframes drop-35 {
  100% {
    top: 110%;
    left: 104%;
  }
}

#result .confetti-36 {
  opacity: .8139;
  background-color: #263672;
  width: 8px;
  height: 3.2px;
  animation: 4.42949s .306729s infinite drop-36;
  top: -10%;
  left: 46%;
  transform: rotate(8.71978deg);
}

@keyframes drop-36 {
  100% {
    top: 110%;
    left: 55%;
  }
}

#result .confetti-37 {
  opacity: .95804;
  background-color: #263672;
  width: 7px;
  height: 2.8px;
  animation: 4.8506s .553129s infinite drop-37;
  top: -10%;
  left: 68%;
  transform: rotate(69.281deg);
}

@keyframes drop-37 {
  100% {
    top: 110%;
    left: 73%;
  }
}

#result .confetti-38 {
  opacity: 1.20766;
  background-color: #263672;
  width: 3px;
  height: 1.2px;
  animation: 4.8202s .307909s infinite drop-38;
  top: -10%;
  left: 100%;
  transform: rotate(357.038deg);
}

@keyframes drop-38 {
  100% {
    top: 110%;
    left: 106%;
  }
}

#result .confetti-39 {
  opacity: .619293;
  background-color: #ffbf00;
  width: 5px;
  height: 2px;
  animation: 4.77614s .974796s infinite drop-39;
  top: -10%;
  left: 8%;
  transform: rotate(231.806deg);
}

@keyframes drop-39 {
  100% {
    top: 110%;
    left: 17%;
  }
}

#result .confetti-40 {
  opacity: 1.4432;
  background-color: #263672;
  width: 5px;
  height: 2px;
  animation: 4.47419s .38126s infinite drop-40;
  top: -10%;
  left: 11%;
  transform: rotate(81.9478deg);
}

@keyframes drop-40 {
  100% {
    top: 110%;
    left: 23%;
  }
}

#result .confetti-41 {
  opacity: .521922;
  background-color: #263672;
  width: 8px;
  height: 3.2px;
  animation: 4.8573s .182609s infinite drop-41;
  top: -10%;
  left: 47%;
  transform: rotate(84.649deg);
}

@keyframes drop-41 {
  100% {
    top: 110%;
    left: 51%;
  }
}

#result .confetti-42 {
  opacity: 1.30542;
  background-color: #d13447;
  width: 5px;
  height: 2px;
  animation: 4.4059s .54866s infinite drop-42;
  top: -10%;
  left: 2%;
  transform: rotate(46.5692deg);
}

@keyframes drop-42 {
  100% {
    top: 110%;
    left: 13%;
  }
}

#result .confetti-43 {
  opacity: .564752;
  background-color: #d13447;
  width: 6px;
  height: 2.4px;
  animation: 4.87897s .531193s infinite drop-43;
  top: -10%;
  left: 39%;
  transform: rotate(9.91834deg);
}

@keyframes drop-43 {
  100% {
    top: 110%;
    left: 47%;
  }
}

#result .confetti-44 {
  opacity: 1.07659;
  background-color: #263672;
  width: 1px;
  height: .4px;
  animation: 4.81459s .437088s infinite drop-44;
  top: -10%;
  left: 24%;
  transform: rotate(79.9527deg);
}

@keyframes drop-44 {
  100% {
    top: 110%;
    left: 39%;
  }
}

#result .confetti-45 {
  opacity: .543613;
  background-color: #263672;
  width: 3px;
  height: 1.2px;
  animation: 4.66938s .960198s infinite drop-45;
  top: -10%;
  left: 89%;
  transform: rotate(88.6861deg);
}

@keyframes drop-45 {
  100% {
    top: 110%;
    left: 97%;
  }
}

#result .confetti-46 {
  opacity: 1.36311;
  background-color: #d13447;
  width: 6px;
  height: 2.4px;
  animation: 4.96149s .771184s infinite drop-46;
  top: -10%;
  left: 38%;
  transform: rotate(54.6774deg);
}

@keyframes drop-46 {
  100% {
    top: 110%;
    left: 39%;
  }
}

#result .confetti-47 {
  opacity: 1.45275;
  background-color: #263672;
  width: 3px;
  height: 1.2px;
  animation: 4.52882s .937947s infinite drop-47;
  top: -10%;
  left: 86%;
  transform: rotate(299.01deg);
}

@keyframes drop-47 {
  100% {
    top: 110%;
    left: 97%;
  }
}

#result .confetti-48 {
  opacity: 1.42919;
  background-color: #263672;
  width: 3px;
  height: 1.2px;
  animation: 4.62553s .528556s infinite drop-48;
  top: -10%;
  left: 15%;
  transform: rotate(303.96deg);
}

@keyframes drop-48 {
  100% {
    top: 110%;
    left: 19%;
  }
}

#result .confetti-49 {
  opacity: 1.22448;
  background-color: #263672;
  width: 5px;
  height: 2px;
  animation: 4.21914s .116186s infinite drop-49;
  top: -10%;
  left: 45%;
  transform: rotate(250.703deg);
}

@keyframes drop-49 {
  100% {
    top: 110%;
    left: 48%;
  }
}

#result .confetti-50 {
  opacity: .798136;
  background-color: #ffbf00;
  width: 4px;
  height: 1.6px;
  animation: 4.82566s .269466s infinite drop-50;
  top: -10%;
  left: 19%;
  transform: rotate(350.418deg);
}

@keyframes drop-50 {
  100% {
    top: 110%;
    left: 29%;
  }
}

#result .confetti-51 {
  opacity: 1.38169;
  background-color: #263672;
  width: 7px;
  height: 2.8px;
  animation: 4.51205s .220871s infinite drop-51;
  top: -10%;
  left: 15%;
  transform: rotate(104.948deg);
}

@keyframes drop-51 {
  100% {
    top: 110%;
    left: 30%;
  }
}

#result .confetti-52 {
  opacity: .988357;
  background-color: #d13447;
  width: 7px;
  height: 2.8px;
  animation: 4.72708s .961628s infinite drop-52;
  top: -10%;
  left: 67%;
  transform: rotate(327.358deg);
}

@keyframes drop-52 {
  100% {
    top: 110%;
    left: 70%;
  }
}

#result .confetti-53 {
  opacity: 1.04107;
  background-color: #d13447;
  width: 1px;
  height: .4px;
  animation: 4.04021s .921894s infinite drop-53;
  top: -10%;
  left: 95%;
  transform: rotate(338.258deg);
}

@keyframes drop-53 {
  100% {
    top: 110%;
    left: 96%;
  }
}

#result .confetti-54 {
  opacity: .70605;
  background-color: #ffbf00;
  width: 4px;
  height: 1.6px;
  animation: 4.54604s .158242s infinite drop-54;
  top: -10%;
  left: 44%;
  transform: rotate(119.261deg);
}

@keyframes drop-54 {
  100% {
    top: 110%;
    left: 53%;
  }
}

#result .confetti-55 {
  opacity: .764097;
  background-color: #d13447;
  width: 4px;
  height: 1.6px;
  animation: 4.58516s .992421s infinite drop-55;
  top: -10%;
  left: 65%;
  transform: rotate(5.24428deg);
}

@keyframes drop-55 {
  100% {
    top: 110%;
    left: 76%;
  }
}

#result .confetti-56 {
  opacity: .500414;
  background-color: #ffbf00;
  width: 1px;
  height: .4px;
  animation: 4.63493s .477102s infinite drop-56;
  top: -10%;
  left: 1%;
  transform: rotate(325.717deg);
}

@keyframes drop-56 {
  100% {
    top: 110%;
    left: 9%;
  }
}

#result .confetti-57 {
  opacity: 1.49332;
  background-color: #263672;
  width: 6px;
  height: 2.4px;
  animation: 4.32953s .455128s infinite drop-57;
  top: -10%;
  left: 1%;
  transform: rotate(345.409deg);
}

@keyframes drop-57 {
  100% {
    top: 110%;
    left: 5%;
  }
}

#result .confetti-58 {
  opacity: 1.1295;
  background-color: #ffbf00;
  width: 6px;
  height: 2.4px;
  animation: 4.2369s .166966s infinite drop-58;
  top: -10%;
  left: 92%;
  transform: rotate(283.131deg);
}

@keyframes drop-58 {
  100% {
    top: 110%;
    left: 96%;
  }
}

#result .confetti-59 {
  opacity: .932239;
  background-color: #d13447;
  width: 3px;
  height: 1.2px;
  animation: 4.66389s .410772s infinite drop-59;
  top: -10%;
  left: 19%;
  transform: rotate(216.853deg);
}

@keyframes drop-59 {
  100% {
    top: 110%;
    left: 29%;
  }
}

#result .confetti-60 {
  opacity: 1.38967;
  background-color: #263672;
  width: 6px;
  height: 2.4px;
  animation: 4.93676s .786701s infinite drop-60;
  top: -10%;
  left: 63%;
  transform: rotate(9.04725deg);
}

@keyframes drop-60 {
  100% {
    top: 110%;
    left: 66%;
  }
}

#result .confetti-61 {
  opacity: .709157;
  background-color: #263672;
  width: 3px;
  height: 1.2px;
  animation: 4.50389s .8253s infinite drop-61;
  top: -10%;
  left: 96%;
  transform: rotate(146.5deg);
}

@keyframes drop-61 {
  100% {
    top: 110%;
    left: 108%;
  }
}

#result .confetti-62 {
  opacity: 1.44378;
  background-color: #d13447;
  width: 8px;
  height: 3.2px;
  animation: 4.18182s .419544s infinite drop-62;
  top: -10%;
  left: 6%;
  transform: rotate(60.93deg);
}

@keyframes drop-62 {
  100% {
    top: 110%;
    left: 20%;
  }
}

#result .confetti-63 {
  opacity: .62762;
  background-color: #d13447;
  width: 8px;
  height: 3.2px;
  animation: 4.56864s .852191s infinite drop-63;
  top: -10%;
  left: 75%;
  transform: rotate(80.9546deg);
}

@keyframes drop-63 {
  100% {
    top: 110%;
    left: 85%;
  }
}

#result .confetti-64 {
  opacity: 1.07774;
  background-color: #d13447;
  width: 5px;
  height: 2px;
  animation: 4.54173s .775248s infinite drop-64;
  top: -10%;
  left: 31%;
  transform: rotate(204.231deg);
}

@keyframes drop-64 {
  100% {
    top: 110%;
    left: 44%;
  }
}

#result .confetti-65 {
  opacity: .955785;
  background-color: #263672;
  width: 5px;
  height: 2px;
  animation: 4.60458s .948109s infinite drop-65;
  top: -10%;
  left: 30%;
  transform: rotate(73.8251deg);
}

@keyframes drop-65 {
  100% {
    top: 110%;
    left: 44%;
  }
}

#result .confetti-66 {
  opacity: 1.15444;
  background-color: #ffbf00;
  width: 7px;
  height: 2.8px;
  animation: 4.6939s .26591s infinite drop-66;
  top: -10%;
  left: 84%;
  transform: rotate(161.305deg);
}

@keyframes drop-66 {
  100% {
    top: 110%;
    left: 96%;
  }
}

#result .confetti-67 {
  opacity: .509604;
  background-color: #ffbf00;
  width: 6px;
  height: 2.4px;
  animation: 4.78232s .648266s infinite drop-67;
  top: -10%;
  left: 46%;
  transform: rotate(234.386deg);
}

@keyframes drop-67 {
  100% {
    top: 110%;
    left: 50%;
  }
}

#result .confetti-68 {
  opacity: 1.44083;
  background-color: #d13447;
  width: 7px;
  height: 2.8px;
  animation: 4.22886s .282885s infinite drop-68;
  top: -10%;
  left: 68%;
  transform: rotate(343.82deg);
}

@keyframes drop-68 {
  100% {
    top: 110%;
    left: 72%;
  }
}

#result .confetti-69 {
  opacity: 1.43076;
  background-color: #263672;
  width: 6px;
  height: 2.4px;
  animation: 4.77465s .66375s infinite drop-69;
  top: -10%;
  left: 53%;
  transform: rotate(343.846deg);
}

@keyframes drop-69 {
  100% {
    top: 110%;
    left: 54%;
  }
}

#result .confetti-70 {
  opacity: .58302;
  background-color: #ffbf00;
  width: 8px;
  height: 3.2px;
  animation: 4.91434s .7149s infinite drop-70;
  top: -10%;
  left: 88%;
  transform: rotate(309.392deg);
}

@keyframes drop-70 {
  100% {
    top: 110%;
    left: 101%;
  }
}

#result .confetti-71 {
  opacity: 1.20443;
  background-color: #ffbf00;
  width: 8px;
  height: 3.2px;
  animation: 4.74492s .89418s infinite drop-71;
  top: -10%;
  left: 18%;
  transform: rotate(282.848deg);
}

@keyframes drop-71 {
  100% {
    top: 110%;
    left: 24%;
  }
}

#result .confetti-72 {
  opacity: 1.05511;
  background-color: #263672;
  width: 8px;
  height: 3.2px;
  animation: 4.70506s .270507s infinite drop-72;
  top: -10%;
  left: 5%;
  transform: rotate(38.2451deg);
}

@keyframes drop-72 {
  100% {
    top: 110%;
    left: 14%;
  }
}

#result .confetti-73 {
  opacity: .839751;
  background-color: #263672;
  width: 5px;
  height: 2px;
  animation: 4.71615s .337885s infinite drop-73;
  top: -10%;
  left: 81%;
  transform: rotate(68.4887deg);
}

@keyframes drop-73 {
  100% {
    top: 110%;
    left: 88%;
  }
}

#result .confetti-74 {
  opacity: 1.37269;
  background-color: #263672;
  width: 1px;
  height: .4px;
  animation: 4.93201s .834459s infinite drop-74;
  top: -10%;
  left: 3%;
  transform: rotate(270.759deg);
}

@keyframes drop-74 {
  100% {
    top: 110%;
    left: 10%;
  }
}

#result .confetti-75 {
  opacity: 1.31301;
  background-color: #ffbf00;
  width: 6px;
  height: 2.4px;
  animation: 4.79251s .785233s infinite drop-75;
  top: -10%;
  left: 51%;
  transform: rotate(12.6373deg);
}

@keyframes drop-75 {
  100% {
    top: 110%;
    left: 65%;
  }
}

#result .confetti-76 {
  opacity: .992284;
  background-color: #d13447;
  width: 8px;
  height: 3.2px;
  animation: 4.23523s .223539s infinite drop-76;
  top: -10%;
  left: 16%;
  transform: rotate(248.426deg);
}

@keyframes drop-76 {
  100% {
    top: 110%;
    left: 21%;
  }
}

#result .confetti-77 {
  opacity: .528995;
  background-color: #d13447;
  width: 8px;
  height: 3.2px;
  animation: 4.63175s .865646s infinite drop-77;
  top: -10%;
  left: 11%;
  transform: rotate(96.9217deg);
}

@keyframes drop-77 {
  100% {
    top: 110%;
    left: 13%;
  }
}

#result .confetti-78 {
  opacity: 1.06331;
  background-color: #ffbf00;
  width: 8px;
  height: 3.2px;
  animation: 4.01432s .907801s infinite drop-78;
  top: -10%;
  left: 51%;
  transform: rotate(93.5672deg);
}

@keyframes drop-78 {
  100% {
    top: 110%;
    left: 66%;
  }
}

#result .confetti-79 {
  opacity: 1.06347;
  background-color: #d13447;
  width: 1px;
  height: .4px;
  animation: 4.08916s .56323s infinite drop-79;
  top: -10%;
  left: 78%;
  transform: rotate(128.473deg);
}

@keyframes drop-79 {
  100% {
    top: 110%;
    left: 82%;
  }
}

#result .confetti-80 {
  opacity: 1.22651;
  background-color: #d13447;
  width: 1px;
  height: .4px;
  animation: 4.04998s .198748s infinite drop-80;
  top: -10%;
  left: 81%;
  transform: rotate(259.404deg);
}

@keyframes drop-80 {
  100% {
    top: 110%;
    left: 88%;
  }
}

#result .confetti-81 {
  opacity: 1.18252;
  background-color: #263672;
  width: 8px;
  height: 3.2px;
  animation: 4.15221s .814118s infinite drop-81;
  top: -10%;
  left: 45%;
  transform: rotate(328.467deg);
}

@keyframes drop-81 {
  100% {
    top: 110%;
    left: 47%;
  }
}

#result .confetti-82 {
  opacity: 1.02557;
  background-color: #ffbf00;
  width: 3px;
  height: 1.2px;
  animation: 4.15961s .492275s infinite drop-82;
  top: -10%;
  left: 50%;
  transform: rotate(191.911deg);
}

@keyframes drop-82 {
  100% {
    top: 110%;
    left: 63%;
  }
}

#result .confetti-83 {
  opacity: 1.22192;
  background-color: #ffbf00;
  width: 8px;
  height: 3.2px;
  animation: 4.3869s .244446s infinite drop-83;
  top: -10%;
  left: 11%;
  transform: rotate(203.983deg);
}

@keyframes drop-83 {
  100% {
    top: 110%;
    left: 13%;
  }
}

#result .confetti-84 {
  opacity: 1.40497;
  background-color: #ffbf00;
  width: 1px;
  height: .4px;
  animation: 4.71855s .386875s infinite drop-84;
  top: -10%;
  left: 45%;
  transform: rotate(191.295deg);
}

@keyframes drop-84 {
  100% {
    top: 110%;
    left: 51%;
  }
}

#result .confetti-85 {
  opacity: 1.34682;
  background-color: #ffbf00;
  width: 7px;
  height: 2.8px;
  animation: 4.58485s 75.2709ms infinite drop-85;
  top: -10%;
  left: 74%;
  transform: rotate(278.466deg);
}

@keyframes drop-85 {
  100% {
    top: 110%;
    left: 86%;
  }
}

#result .confetti-86 {
  opacity: .755273;
  background-color: #263672;
  width: 3px;
  height: 1.2px;
  animation: 4.31064s .316553s infinite drop-86;
  top: -10%;
  left: 81%;
  transform: rotate(257.447deg);
}

@keyframes drop-86 {
  100% {
    top: 110%;
    left: 87%;
  }
}

#result .confetti-87 {
  opacity: 1.17988;
  background-color: #ffbf00;
  width: 7px;
  height: 2.8px;
  animation: 4.63348s .819087s infinite drop-87;
  top: -10%;
  left: 94%;
  transform: rotate(160.71deg);
}

@keyframes drop-87 {
  100% {
    top: 110%;
    left: 98%;
  }
}

#result .confetti-88 {
  opacity: 1.31659;
  background-color: #ffbf00;
  width: 8px;
  height: 3.2px;
  animation: 4.59387s .130682s infinite drop-88;
  top: -10%;
  left: 54%;
  transform: rotate(32.604deg);
}

@keyframes drop-88 {
  100% {
    top: 110%;
    left: 66%;
  }
}

#result .confetti-89 {
  opacity: 1.00819;
  background-color: #263672;
  width: 4px;
  height: 1.6px;
  animation: 4.95924s .511754s infinite drop-89;
  top: -10%;
  left: 60%;
  transform: rotate(18.629deg);
}

@keyframes drop-89 {
  100% {
    top: 110%;
    left: 74%;
  }
}

#result .confetti-90 {
  opacity: .717791;
  background-color: #ffbf00;
  width: 8px;
  height: 3.2px;
  animation: 4.86704s 67.5602ms infinite drop-90;
  top: -10%;
  left: 10%;
  transform: rotate(347.818deg);
}

@keyframes drop-90 {
  100% {
    top: 110%;
    left: 19%;
  }
}

#result .confetti-91 {
  opacity: .669548;
  background-color: #d13447;
  width: 5px;
  height: 2px;
  animation: 4.89797s .105038s infinite drop-91;
  top: -10%;
  left: 67%;
  transform: rotate(320.138deg);
}

@keyframes drop-91 {
  100% {
    top: 110%;
    left: 79%;
  }
}

#result .confetti-92 {
  opacity: 1.03278;
  background-color: #263672;
  width: 8px;
  height: 3.2px;
  animation: 4.15784s .532094s infinite drop-92;
  top: -10%;
  left: 25%;
  transform: rotate(276.939deg);
}

@keyframes drop-92 {
  100% {
    top: 110%;
    left: 37%;
  }
}

#result .confetti-93 {
  opacity: 1.31383;
  background-color: #ffbf00;
  width: 7px;
  height: 2.8px;
  animation: 4.50089s .53852s infinite drop-93;
  top: -10%;
  left: 57%;
  transform: rotate(54.5022deg);
}

@keyframes drop-93 {
  100% {
    top: 110%;
    left: 71%;
  }
}

#result .confetti-94 {
  opacity: 1.41398;
  background-color: #263672;
  width: 5px;
  height: 2px;
  animation: 4.41785s .404617s infinite drop-94;
  top: -10%;
  left: 82%;
  transform: rotate(326.407deg);
}

@keyframes drop-94 {
  100% {
    top: 110%;
    left: 97%;
  }
}

#result .confetti-95 {
  opacity: 1.27552;
  background-color: #ffbf00;
  width: 4px;
  height: 1.6px;
  animation: 4.56758s .794971s infinite drop-95;
  top: -10%;
  left: 21%;
  transform: rotate(55.5789deg);
}

@keyframes drop-95 {
  100% {
    top: 110%;
    left: 27%;
  }
}

#result .confetti-96 {
  opacity: 1.3124;
  background-color: #d13447;
  width: 3px;
  height: 1.2px;
  animation: 4.79544s 42.8089ms infinite drop-96;
  top: -10%;
  left: 39%;
  transform: rotate(271.408deg);
}

@keyframes drop-96 {
  100% {
    top: 110%;
    left: 51%;
  }
}

#result .confetti-97 {
  opacity: .669253;
  background-color: #ffbf00;
  width: 4px;
  height: 1.6px;
  animation: 4.64125s .891367s infinite drop-97;
  top: -10%;
  left: 71%;
  transform: rotate(38.2883deg);
}

@keyframes drop-97 {
  100% {
    top: 110%;
    left: 79%;
  }
}

#result .confetti-98 {
  opacity: 1.3958;
  background-color: #d13447;
  width: 6px;
  height: 2.4px;
  animation: 4.30609s 83.9355ms infinite drop-98;
  top: -10%;
  left: 64%;
  transform: rotate(35.2104deg);
}

@keyframes drop-98 {
  100% {
    top: 110%;
    left: 73%;
  }
}

#result .confetti-99 {
  opacity: 1.31697;
  background-color: #263672;
  width: 3px;
  height: 1.2px;
  animation: 4.74835s .339936s infinite drop-99;
  top: -10%;
  left: 63%;
  transform: rotate(334.057deg);
}

@keyframes drop-99 {
  100% {
    top: 110%;
    left: 75%;
  }
}

#result .confetti-100 {
  opacity: .738254;
  background-color: #d13447;
  width: 4px;
  height: 1.6px;
  animation: 4.71208s .497483s infinite drop-100;
  top: -10%;
  left: 20%;
  transform: rotate(127.685deg);
}

@keyframes drop-100 {
  100% {
    top: 110%;
    left: 26%;
  }
}

.game-stat-card {
  color: #fff;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 362px;
  padding: 16px 22px;
  line-height: 1;
  display: flex;
  position: relative;
}

.game-stat-card img, .game-stat-card .no-img {
  aspect-ratio: 1;
  flex: 0 0 32px;
  max-height: 32px;
}

.game-stat-card .no-img {
  border: 1px solid #5b5b5b;
}

.game-stat-card .display-name {
  text-align: left;
  text-transform: uppercase;
  flex: 1;
  font-size: 14px;
  font-weight: 400;
}

.game-stat-card .stat-value {
  font-size: 16px;
  font-weight: 700;
}

.game-stat-card.accomplished {
  border: 1px solid #80ffdb;
}

.game-stat-card.accomplished .stat-value {
  color: #80ffdb;
}

.game-stat-card.md {
  width: 300px;
}

.game-stat-card.sm {
  flex-direction: column;
  gap: 20px;
  width: 102px;
}

.game-stat-card.sm img {
  opacity: .5;
  height: 36px;
}

.game-stat-card.sm > div {
  opacity: .5;
}

.rotating-sections .current-section {
  animation: 1s linear forwards simple-fade-in;
}

.rotating-sections .current-section.fading-out {
  animation: 1s linear forwards simple-fade-out;
}

.vertical-timer {
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.vertical-timer .infinite .finite {
  background-color: #80ffdb;
  border-radius: 0 0 6px 6px;
}

@keyframes infiniteTimer {
  0% {
    transform: translateY(99%)scaleY(10%);
  }

  10% {
    transform: translateY(99%)scaleY(10%);
  }

  50% {
    transform: scaleY(50%);
  }

  90% {
    transform: translateY(-1%)scaleY(10%);
  }

  100% {
    transform: translateY(-10%)scaleY(10%);
  }
}

.finite {
  border-radius: 0 0 6px 6px;
  width: 100%;
  transition: height .2s;
}

.infinite {
  transform-origin: top;
  border-radius: 6px;
  width: 100%;
  height: 100%;
}

.dark-background {
  background: #121212;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.dark-background .gradation {
  z-index: 2;
  background: linear-gradient(170deg, #0000 0%, #0b1c1d00 42%, #122e2fcc 73%, #266265e6 90%);
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.dark-background .squares {
  z-index: 1;
  width: 100%;
  height: 30%;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.dark-background .squares .square {
  border-radius: 2px;
  width: 11px;
  height: 11px;
  animation: 20s ease-out forwards floating-fading;
  position: absolute;
  bottom: 50%;
}

.dark-background .squares .square.pattern-0 {
  background: linear-gradient(79.56deg, #232f43 7.78%, #2f2442 80.6%, #22515a 126.27%);
}

.dark-background .squares .square.pattern-1 {
  background: linear-gradient(79.56deg, #223949 7.78%, #3a2245 75.66%, #2f2442 126.27%);
}

.dark-background .squares .square.pattern-2 {
  background: linear-gradient(79.56deg, #266265 7.78%, #232f43 75.66%, #2f2442 126.27%);
}

.game-waiting {
  background: #121212;
  min-height: 100vh;
  margin: -1rem 0;
  font-family: Fira Sans;
  font-size: 14px;
  line-height: 2.4;
  display: flex;
}

.game-waiting .waiting-hint {
  z-index: 1;
  color: #eaeaea;
  background: #000;
  border-right: 1px solid #2d2d2d;
  align-items: center;
  width: 450px;
  height: 100vh;
  padding: 2rem 0 0 64px;
  font-family: Fira Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 2.4;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.game-waiting .waiting-hint .vertical-timer-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.game-waiting .waiting-players {
  flex-grow: 2;
  justify-content: center;
  align-items: center;
  max-width: none;
  margin-left: 450px;
  display: flex;
  position: relative;
}

.game-waiting .waiting-players .players-inner {
  z-index: 10;
  width: 95%;
  position: relative;
}

.game-waiting .waiting-players .heading {
  text-align: center;
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: bold;
}

.game-waiting .waiting-players .heading .status-message, .game-waiting .waiting-players .heading .blinker {
  color: #fff;
}

.game-waiting .waiting-players .heading .blinker {
  margin: 0 8px 0 2px;
}

.game-waiting .waiting-players .heading .player-count {
  color: #cbcbcb;
}

.game-waiting .waiting-players .players {
  flex-wrap: wrap;
  place-content: flex-start;
  gap: 12px 16px;
  width: 100%;
  min-height: 300px;
  display: flex;
}

@media screen and (width <= 1100px) {
  .game-waiting .waiting-players .players {
    justify-content: center;
  }
}

.game-waiting .waiting-players .players .player {
  color: #fffc;
  white-space: nowrap;
  background-color: #00000080;
  border: 1px solid #2d2d2d;
  border-radius: 2px;
  align-items: center;
  gap: 8px;
  width: 279px;
  height: 34px;
  padding: 0 15px 0 12px;
  font-family: Fira Mono;
  animation-name: fade-in;
  animation-duration: 1s;
  animation-iteration-count: 1;
  display: flex;
}

.game-waiting .waiting-players .players .player.me {
  border-color: #80ffdb;
}

.game-waiting .waiting-players .players .player .tag-wrapper {
  min-width: 34px;
}

.game-waiting .waiting-players .players .player .level-tag {
  display: inline-flex;
}

.game-waiting .waiting-players .players .player .player-tag {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
  flex: 1;
  line-height: 14px;
  overflow: hidden;
}

.game-waiting .waiting-players .players .player .tones {
  text-align: right;
  color: #fffc;
  width: 34px;
}

.game-waiting .waiting-players .players .player i {
  color: #fffc;
}

.game-waiting .waiting-players .elapsed-time {
  text-align: center;
  color: #222;
  background: #fff;
  width: 56px;
  height: 56px;
  margin-left: auto;
  font-size: 22px;
  line-height: 54px;
}

.game-waiting .waiting-players .bg-wrapper {
  width: calc(100% - 450px);
  height: 100vh;
  position: fixed;
  bottom: 0;
  right: 0;
}

.game-waiting .floating-menu {
  z-index: 1;
  background: #0000004d;
  border: 1px solid #2d2d2d;
  border-radius: 4px;
  padding: 16px 24px;
  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
}

.game-waiting .floating-menu button {
  display: inline-block;
}

.game-waiting .floating-menu button:not(:last-child) {
  margin-right: 24px;
}

@keyframes blinker {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.blinker {
  animation-name: blinker;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

@keyframes semi-blinker {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.semi-blinker {
  animation-name: semi-blinker;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes color-rotation {
  0% {
    background-color: #7fdbda;
  }

  25% {
    background-color: #f55353;
  }

  50% {
    background-color: #feb139;
  }

  75% {
    background-color: #ffea11;
  }

  100% {
    background-color: #7fdbda;
  }
}

.textfield {
  background: #000;
  border: 1px solid #0000;
  margin: 18px 0;
  padding: 12px;
  font-family: Fira Sans;
  font-size: 14px;
  line-height: 1;
  position: relative;
}

.textfield input {
  font-size: inherit;
  color: #fff;
  background: none;
  border: none;
  width: 100%;
}

.textfield input:focus {
  outline: none;
}

.textfield label {
  color: #cbcbcb;
  font-size: 14px;
  line-height: 1;
  transition: all .3s;
  position: absolute;
  top: 14px;
  left: 12px;
}

.textfield .textfield-hint {
  color: #cbcbcb;
  padding-top: 8px;
  font-size: 10px;
  position: absolute;
  bottom: -18px;
  left: 0;
}

.textfield.focused {
  background: none;
  border: 1px solid #80ffdb;
}

.textfield.focused label {
  font-size: 10px;
  top: -16px;
  left: 0;
}

.sign-in-box {
  text-align: center;
  width: 370px;
  max-width: 95vw;
  padding: 60px 32px;
  transition: all 1s;
  animation: 1s ease-in fade-in;
}

.sign-in-box .heading {
  margin-bottom: 42px;
}

.sign-in-box .heading .gamename {
  color: #80ffdb;
  letter-spacing: -.07em;
  font-family: Fira Sans;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.5;
}

.sign-in-box .heading .subtitle {
  color: #b3b3b3;
  font-weight: 300;
}

.sign-in-box .form > div {
  margin-bottom: 40px;
}

.sign-in-box .form .message {
  color: #fff;
  margin-top: 6px;
  margin-bottom: 18px;
  font-size: 14px;
}

.sign-in-box .message {
  color: #fff;
}

.sign-in-box .actions button {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.sign-in-box .actions button:not(:last-child) {
  margin-bottom: 26px;
}

.loading-grid {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.loading-grid div {
  background: #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: 1.2s linear infinite loading-grid;
  position: absolute;
}

.loading-grid div:first-child {
  animation-delay: 0s;
  top: 8px;
  left: 8px;
}

.loading-grid div:nth-child(2) {
  animation-delay: -.4s;
  top: 8px;
  left: 32px;
}

.loading-grid div:nth-child(3) {
  animation-delay: -.8s;
  top: 8px;
  left: 56px;
}

.loading-grid div:nth-child(4) {
  animation-delay: -.4s;
  top: 32px;
  left: 8px;
}

.loading-grid div:nth-child(5) {
  animation-delay: -.8s;
  top: 32px;
  left: 32px;
}

.loading-grid div:nth-child(6) {
  animation-delay: -1.2s;
  top: 32px;
  left: 56px;
}

.loading-grid div:nth-child(7) {
  animation-delay: -.8s;
  top: 56px;
  left: 8px;
}

.loading-grid div:nth-child(8) {
  animation-delay: -1.2s;
  top: 56px;
  left: 32px;
}

.loading-grid div:nth-child(9) {
  animation-delay: -1.6s;
  top: 56px;
  left: 56px;
}

@keyframes loading-grid {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

.landing .center-content {
  z-index: 10;
  width: 400px;
  max-width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.beta-leaderboard .beta-leaderboard-section {
  padding: 24px;
}

.beta-leaderboard .beta-leaderboard-section .title {
  color: #fffc;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
  font-family: Fira Sans;
  font-size: 18px;
}

.beta-leaderboard .beta-leaderboard-section .title img {
  vertical-align: middle;
  margin-right: 8px;
}

.beta-leaderboard .beta-leaderboard-section:not(:last-child) {
  margin-bottom: 12px;
}

.beta-leaderboard .beta-leaderboard-section table {
  border-spacing: 0;
  width: 100%;
}

.beta-leaderboard .beta-leaderboard-section table tr {
  box-shadow: inset 0 -1px #2d2d2d;
}

.beta-leaderboard .beta-leaderboard-section table thead th {
  text-align: right;
  color: #fffc;
  text-transform: uppercase;
  font-family: Fira Sans;
  font-weight: 400;
}

.beta-leaderboard .beta-leaderboard-section table thead th.left {
  text-align: left;
}

.beta-leaderboard .beta-leaderboard-section table thead tr th {
  padding: 8px 4px;
  font-size: 12px;
}

.beta-leaderboard .beta-leaderboard-section table thead tr th:first-child {
  padding-left: 0;
}

.beta-leaderboard .beta-leaderboard-section table thead tr th:last-child {
  padding-right: 4px;
}

.beta-leaderboard .beta-leaderboard-section table tbody tr td {
  color: #fff;
  text-align: right;
  height: 40px;
  padding: 4px;
  font-family: Fira Mono;
  font-size: 14px;
  font-weight: 400;
}

.beta-leaderboard .beta-leaderboard-section table tbody tr td .marker {
  vertical-align: middle;
  border: 1px solid #fff;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  display: inline-block;
}

.beta-leaderboard .beta-leaderboard-section table tbody tr td:first-child {
  padding-left: 0;
}

.beta-leaderboard .beta-leaderboard-section table tbody tr td:last-child {
  padding-right: 10px;
}

.beta-leaderboard .beta-leaderboard-section table tbody td.col-ranking {
  color: #5b5b5b;
  text-align: center;
  text-align: right;
  font-family: Fira Mono;
  font-size: 24px;
  font-weight: bold;
}

.beta-leaderboard .beta-leaderboard-section table tbody td.col-player-tag {
  text-align: left;
}

.beta-leaderboard .beta-leaderboard-section table tbody tr.my td.col-player-tag {
  color: #80ffdb;
}

.beta-leaderboard .beta-leaderboard-section table tbody tr.unranked td {
  padding-top: 24px;
}

.how-to-play {
  color: #fff;
  font-size: 14px;
}

.how-to-play .content-wrapper {
  width: 750px;
  height: 500px;
  position: relative;
}

.how-to-play .content-wrapper .step {
  flex-wrap: nowrap;
  padding: 32px;
  display: flex;
  position: absolute;
}

.how-to-play .content-wrapper .step.step-1 {
  border-bottom: 1px solid #2d2d2d;
  width: 500px;
  height: 250px;
  top: 0;
  left: 0;
}

.how-to-play .content-wrapper .step.step-1 .text-block {
  flex-grow: 1;
  padding-right: 8px;
}

.how-to-play .content-wrapper .step.step-1 .image-block {
  flex-basis: 160px;
}

.how-to-play .content-wrapper .step.step-2 {
  flex-direction: column;
  justify-content: space-around;
  width: 500px;
  height: 250px;
  top: 250px;
  left: 0;
}

.how-to-play .content-wrapper .step.step-3 {
  border-left: 1px solid #2d2d2d;
  flex-direction: column;
  justify-content: space-evenly;
  width: 250px;
  height: 500px;
  top: 0;
  left: 500px;
}

.how-to-play .content-wrapper .step .image-block {
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.how-to-play .content-wrapper .step .image-block img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.how-to-play .content-wrapper .step .text-block {
  color: #fff;
  font-size: 16px;
  line-height: 1.4;
}

.how-to-play .content-wrapper .step .text-block .number {
  font-size: 18px;
  font-weight: bold;
}

.how-to-play .content-wrapper .step .text-block .highlighted {
  color: #80ffdb;
  font-weight: bold;
}

.main-container.center {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  display: flex;
}

.main-container.center .content {
  z-index: 1;
  padding: 48px 12px;
}

.main-container.center .content .tile {
  width: 600px;
}

.main-container.center .story {
  color: #fff;
  max-width: 100%;
  padding: 48px 102px 54px 54px;
  font-size: 14px;
  line-height: 2.5;
  animation: 1s fade-in;
}

.main-container.center .menu {
  text-align: center;
  margin-top: 36px;
  animation: 1.5s fade-in-delayed;
}

.main-container.center .menu .nav-button {
  width: 120px;
  margin-bottom: 16px;
  margin-left: 8px;
  margin-right: 8px;
  font-weight: bold;
}

.main-container.center .menu .nav-button:not(:hover) {
  color: #303f9f;
}

.main-container.center .menu .button-wrapper {
  display: inline-block;
}

.main-container.center .menu .button-wrapper:not(:last-child) {
  margin-right: 24px;
}

.main-container.center .menu .button-wrapper .nav-button {
  background-image: linear-gradient(to right, #b8cbb8 0% 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66% 100%);
  transition: font-size .2s;
}

.main-container.center .menu .button-wrapper .nav-button:hover {
  color: #fff;
  animation: 1s ease-out infinite pulse;
}

.main-container.center .hall-of-fame-button {
  cursor: pointer;
  color: #fff;
  background-color: #0000;
  border: none;
  border-radius: 6px;
  padding: 12px 16px;
  font-family: inherit;
  transition: background-color .5s;
  position: fixed;
  bottom: 16px;
  right: 24px;
  overflow: hidden;
}

.main-container.center .hall-of-fame-button span, .main-container.center .hall-of-fame-button i {
  text-align: center;
  display: block;
}

.main-container.center .hall-of-fame-button i {
  margin-bottom: 8px;
  font-size: 36px;
  transition: all .3s;
}

.main-container.center .hall-of-fame-button span {
  letter-spacing: 0;
  font-size: 12px;
}

.main-container.center .hall-of-fame-button .button-background {
  z-index: -1;
  opacity: 1;
  background: radial-gradient(circle, #060435 0%, #090979 35%, #00d4ff 100%);
  width: 100%;
  height: 600px;
  transition: opacity .2s;
  animation: 5s infinite button-background-effect;
  position: absolute;
  top: 0;
  left: 0;
}

.main-container.center .hall-of-fame-button:hover {
  text-shadow: 0 0 4px #fff;
  background-color: #f3abc4;
}

.main-container.center .hall-of-fame-button:hover i {
  transform: scale(1.15);
}

.main-container.center .hall-of-fame-button:hover .button-background {
  opacity: .8;
}

.main-container.center .popup-wrapper .how-to-popup {
  z-index: 99;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-container.center .popup-wrapper .how-to-popup button {
  margin: 12px auto 0;
}

.main-container.center .popup-wrapper .how-to-backdrop {
  z-index: 9;
  background-color: #181818e6;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

@keyframes fade-in-delayed {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes button-background-effect {
  0% {
    transform: translateY(0)scale(1);
  }

  40% {
    transform: translateY(-400px)scale(1.5);
  }

  100% {
    transform: translateY(0)scale(1);
  }
}

.main-container.how-to {
  animation: .5s linear forwards simple-fade-in;
}

.main-container.how-to .how-to-inner {
  z-index: 1;
  max-width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-container.how-to .how-to-inner .header .title {
  color: #fffc;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 1.3;
}

.main-container.how-to .how-to-inner .tile-wrapper {
  max-width: 100%;
  overflow-x: auto;
}

.main-container.how-to .how-to-inner .actions {
  text-align: center;
  margin-top: 32px;
}

.main-container.how-to .how-to-inner .actions .play-button-wrapper {
  display: inline-block;
}

.tone-palette:not(.bucketized) {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.tone-palette:not(.bucketized) .color {
  border: 1px solid #fff;
  width: 36px;
  height: 36px;
  margin: 2px;
  position: relative;
}

.tone-palette:not(.bucketized) .color.selected {
  border: 5px solid #ff9b00;
}

.tone-palette:not(.bucketized) .color .count {
  color: #222;
  background: #fff;
  padding: 0 4px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
}

.tone-palette.bucketized {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  column-gap: 4px;
  display: flex;
}

.tone-palette.bucketized .color-column .tone {
  opacity: 0;
  border-radius: 2px;
  width: 16px;
  height: 16px;
}

.tone-palette.bucketized .color-column .same-tone {
  opacity: 0;
  border: 3px solid #bbac;
  border-radius: 2px;
  margin: -4px;
  padding: 1px;
}

.tone-palette.bucketized .color-column :not(:last-child) {
  margin-bottom: 3px;
}

.tone-palette.bucketized .fade-in-0 {
  animation: .5s forwards fade-in;
}

.tone-palette.bucketized .fade-in-1 {
  animation: .5s 30ms forwards fade-in;
}

.tone-palette.bucketized .fade-in-2 {
  animation: .5s 60ms forwards fade-in;
}

.tone-palette.bucketized .fade-in-3 {
  animation: .5s 90ms forwards fade-in;
}

.tone-palette.bucketized .fade-in-4 {
  animation: .5s .12s forwards fade-in;
}

.tone-palette.bucketized .fade-in-5 {
  animation: .5s .15s forwards fade-in;
}

.tone-palette.bucketized .fade-in-6 {
  animation: .5s .18s forwards fade-in;
}

.tone-palette.bucketized .fade-in-7 {
  animation: .5s .21s forwards fade-in;
}

.tone-palette.bucketized .fade-in-8 {
  animation: .5s .24s forwards fade-in;
}

.tone-palette.bucketized .fade-in-9 {
  animation: .5s .27s forwards fade-in;
}

.tone-palette.bucketized .fade-in-10 {
  animation: .5s .3s forwards fade-in;
}

.tone-palette.bucketized .fade-in-11 {
  animation: .5s .33s forwards fade-in;
}

.tone-palette.bucketized .fade-in-12 {
  animation: .5s .36s forwards fade-in;
}

.tone-palette.bucketized .fade-in-13 {
  animation: .5s .39s forwards fade-in;
}

.tone-palette.bucketized .fade-in-14 {
  animation: .5s .42s forwards fade-in;
}

.tone-palette.bucketized .fade-in-15 {
  animation: .5s .45s forwards fade-in;
}

.tone-palette.bucketized .fade-in-16 {
  animation: .5s .48s forwards fade-in;
}

.tone-palette.bucketized .fade-in-17 {
  animation: .5s .51s forwards fade-in;
}

.tone-palette.bucketized .fade-in-18 {
  animation: .5s .54s forwards fade-in;
}

.tone-palette.bucketized .fade-in-19 {
  animation: .5s .57s forwards fade-in;
}

.tone-palette.bucketized .fade-in-20 {
  animation: .5s .6s forwards fade-in;
}

.tone-palette.bucketized .fade-in-21 {
  animation: .5s .63s forwards fade-in;
}

.tone-palette.bucketized .fade-in-22 {
  animation: .5s .66s forwards fade-in;
}

.tone-palette.bucketized .fade-in-23 {
  animation: .5s .69s forwards fade-in;
}

.tone-palette.bucketized .fade-in-24 {
  animation: .5s .72s forwards fade-in;
}

.tone-palette.bucketized .fade-in-25 {
  animation: .5s .75s forwards fade-in;
}

.tone-palette.bucketized .fade-in-26 {
  animation: .5s .78s forwards fade-in;
}

.tone-palette.bucketized .fade-in-27 {
  animation: .5s .81s forwards fade-in;
}

.tone-palette.bucketized .fade-in-28 {
  animation: .5s .84s forwards fade-in;
}

.tone-palette.bucketized .fade-in-29 {
  animation: .5s .87s forwards fade-in;
}

.tone-palette.bucketized .fade-in-30 {
  animation: .5s .9s forwards fade-in;
}

.tone-palette.bucketized .fade-in-31 {
  animation: .5s .93s forwards fade-in;
}

.tone-palette.bucketized .fade-in-32 {
  animation: .5s .96s forwards fade-in;
}

.tone-palette.bucketized .fade-in-33 {
  animation: .5s .99s forwards fade-in;
}

.tone-palette.bucketized .fade-in-34 {
  animation: .5s 1.02s forwards fade-in;
}

.tone-palette.bucketized .fade-in-35 {
  animation: .5s 1.05s forwards fade-in;
}

.tone-palette.bucketized .fade-in-36 {
  animation: .5s 1.08s forwards fade-in;
}

.tone-palette.bucketized .fade-in-37 {
  animation: .5s 1.11s forwards fade-in;
}

.tone-palette.bucketized .fade-in-38 {
  animation: .5s 1.14s forwards fade-in;
}

.tone-palette.bucketized .fade-in-39 {
  animation: .5s 1.17s forwards fade-in;
}

.tone-palette.bucketized .fade-in-40 {
  animation: .5s 1.2s forwards fade-in;
}

.tone-palette.bucketized .fade-in-41 {
  animation: .5s 1.23s forwards fade-in;
}

.tone-palette.bucketized .fade-in-42 {
  animation: .5s 1.26s forwards fade-in;
}

.tone-palette.bucketized .fade-in-43 {
  animation: .5s 1.29s forwards fade-in;
}

.tone-palette.bucketized .fade-in-44 {
  animation: .5s 1.32s forwards fade-in;
}

.tone-palette.bucketized .fade-in-45 {
  animation: .5s 1.35s forwards fade-in;
}

.tone-palette.bucketized .fade-in-46 {
  animation: .5s 1.38s forwards fade-in;
}

.tone-palette.bucketized .fade-in-47 {
  animation: .5s 1.41s forwards fade-in;
}

.tone-palette.bucketized .fade-in-48 {
  animation: .5s 1.44s forwards fade-in;
}

.tone-palette.bucketized .fade-in-49 {
  animation: .5s 1.47s forwards fade-in;
}

.tone-palette.bucketized .fade-in-50 {
  animation: .5s 1.5s forwards fade-in;
}

.tone-palette.bucketized .fade-in-51 {
  animation: .5s 1.53s forwards fade-in;
}

.tone-palette.bucketized .fade-in-52 {
  animation: .5s 1.56s forwards fade-in;
}

.tone-palette.bucketized .fade-in-53 {
  animation: .5s 1.59s forwards fade-in;
}

.tone-palette.bucketized .fade-in-54 {
  animation: .5s 1.62s forwards fade-in;
}

.tone-palette.bucketized .fade-in-55 {
  animation: .5s 1.65s forwards fade-in;
}

.tone-palette.bucketized .fade-in-56 {
  animation: .5s 1.68s forwards fade-in;
}

.tone-palette.bucketized .fade-in-57 {
  animation: .5s 1.71s forwards fade-in;
}

.tone-palette.bucketized .fade-in-58 {
  animation: .5s 1.74s forwards fade-in;
}

.tone-palette.bucketized .fade-in-59 {
  animation: .5s 1.77s forwards fade-in;
}

.tone-palette.bucketized .fade-in-60 {
  animation: .5s 1.8s forwards fade-in;
}

.tone-palette.bucketized .fade-in-61 {
  animation: .5s 1.83s forwards fade-in;
}

.tone-palette.bucketized .fade-in-62 {
  animation: .5s 1.86s forwards fade-in;
}

.tone-palette.bucketized .fade-in-63 {
  animation: .5s 1.89s forwards fade-in;
}

.tone-palette.bucketized .fade-in-64 {
  animation: .5s 1.92s forwards fade-in;
}

.tone-palette.bucketized .fade-in-65 {
  animation: .5s 1.95s forwards fade-in;
}

.tone-palette.bucketized .fade-in-66 {
  animation: .5s 1.98s forwards fade-in;
}

.tone-palette.bucketized .fade-in-67 {
  animation: .5s 2.01s forwards fade-in;
}

.tone-palette.bucketized .fade-in-68 {
  animation: .5s 2.04s forwards fade-in;
}

.tone-palette.bucketized .fade-in-69 {
  animation: .5s 2.07s forwards fade-in;
}

.tone-palette.bucketized .fade-in-70 {
  animation: .5s 2.1s forwards fade-in;
}

.tone-palette.bucketized .fade-in-71 {
  animation: .5s 2.13s forwards fade-in;
}

.tone-palette.bucketized .fade-in-72 {
  animation: .5s 2.16s forwards fade-in;
}

.tone-palette.bucketized .fade-in-73 {
  animation: .5s 2.19s forwards fade-in;
}

.tone-palette.bucketized .fade-in-74 {
  animation: .5s 2.22s forwards fade-in;
}

.tone-palette.bucketized .fade-in-75 {
  animation: .5s 2.25s forwards fade-in;
}

.tone-palette.bucketized .fade-in-76 {
  animation: .5s 2.28s forwards fade-in;
}

.tone-palette.bucketized .fade-in-77 {
  animation: .5s 2.31s forwards fade-in;
}

.tone-palette.bucketized .fade-in-78 {
  animation: .5s 2.34s forwards fade-in;
}

.tone-palette.bucketized .fade-in-79 {
  animation: .5s 2.37s forwards fade-in;
}

.tone-palette.bucketized .fade-in-80 {
  animation: .5s 2.4s forwards fade-in;
}

.tone-palette.bucketized .fade-in-81 {
  animation: .5s 2.43s forwards fade-in;
}

.tone-palette.bucketized .fade-in-82 {
  animation: .5s 2.46s forwards fade-in;
}

.tone-palette.bucketized .fade-in-83 {
  animation: .5s 2.49s forwards fade-in;
}

.tone-palette.bucketized .fade-in-84 {
  animation: .5s 2.52s forwards fade-in;
}

.tone-palette.bucketized .fade-in-85 {
  animation: .5s 2.55s forwards fade-in;
}

.tone-palette.bucketized .fade-in-86 {
  animation: .5s 2.58s forwards fade-in;
}

.tone-palette.bucketized .fade-in-87 {
  animation: .5s 2.61s forwards fade-in;
}

.tone-palette.bucketized .fade-in-88 {
  animation: .5s 2.64s forwards fade-in;
}

.tone-palette.bucketized .fade-in-89 {
  animation: .5s 2.67s forwards fade-in;
}

.tone-palette.bucketized .fade-in-90 {
  animation: .5s 2.7s forwards fade-in;
}

.tone-palette.bucketized .fade-in-91 {
  animation: .5s 2.73s forwards fade-in;
}

.tone-palette.bucketized .fade-in-92 {
  animation: .5s 2.76s forwards fade-in;
}

.tone-palette.bucketized .fade-in-93 {
  animation: .5s 2.79s forwards fade-in;
}

.tone-palette.bucketized .fade-in-94 {
  animation: .5s 2.82s forwards fade-in;
}

.tone-palette.bucketized .fade-in-95 {
  animation: .5s 2.85s forwards fade-in;
}

.tone-palette.bucketized .fade-in-96 {
  animation: .5s 2.88s forwards fade-in;
}

.tone-palette.bucketized .fade-in-97 {
  animation: .5s 2.91s forwards fade-in;
}

.tone-palette.bucketized .fade-in-98 {
  animation: .5s 2.94s forwards fade-in;
}

.tone-palette.bucketized .fade-in-99 {
  animation: .5s 2.97s forwards fade-in;
}

.tone-palette.bucketized .fade-in-100 {
  animation: .5s 3s forwards fade-in;
}

.grid {
  grid-template-rows: repeat(12, 64px) 1fr;
  grid-template-columns: 320px 1fr;
  column-gap: 16px;
  height: fit-content;
  min-height: 100dvh;
  padding: 0 64px 48px;
  display: grid;
}

.profile {
  color: #fff;
}

.profile .profile-inner {
  z-index: 1;
  position: relative;
}

.profile .player-profile-stats {
  grid-row: 3 / 10 span;
  grid-template-rows: subgrid;
  background: #0003;
  border: 1px solid #2d2d2d;
  width: 320px;
  padding: 0 32px;
  display: grid;
}

.profile .player-profile-stats img {
  width: 24px;
  margin-right: 16px;
}

.profile .player-profile-stats .player-tag {
  vertical-align: middle;
  margin-right: 10px;
  font-family: Fira Sans;
  font-size: 28px;
  font-weight: 100;
}

.profile .player-profile-stats .profile-row {
  grid-row: 1 span;
  place-self: start center;
  width: 100%;
}

.profile .player-profile-stats .profile-row:first-of-type {
  gap: 12px;
}

.profile .player-profile-stats .profile-row.overall .level-tones > * {
  display: inline-block;
}

.profile .player-profile-stats .profile-row.overall .to-next {
  text-align: right;
  color: #fffc;
  font-family: Fira Sans;
  font-size: 12px;
}

.profile .player-profile-stats .profile-row.overall .to-next .next {
  color: #fff;
  margin-right: 8px;
  font-weight: bold;
}

.profile .player-profile-stats .profile-row.overall :global(.chart-values) {
  fill: #fffc;
}

.profile .player-profile-stats .profile-row.overall :global(.pie-value) {
  font-weight: bold;
}

.profile .player-profile-stats .profile-row.wins, .profile .player-profile-stats .profile-row.tones, .profile .player-profile-stats .profile-row.top-class-played, .profile .player-profile-stats .profile-row.top-class-won {
  align-items: start;
  display: flex;
}

.profile .player-profile-stats .profile-row.rate {
  text-align: center;
  grid-row: 4 span;
  align-self: end;
}

.profile .player-tones {
  grid-row: 3 / 9 span;
  grid-template-rows: subgrid;
  grid-template-columns: subgrid;
  font-family: Fira Sans;
  display: grid;
}

.profile .player-tones > * {
  justify-self: center;
}

.profile .player-tones > :last-child {
  grid-row: 4 / 8 span;
  height: fit-content;
  padding-bottom: 48px;
}

.profile .player-tones .tone-count {
  grid-area: 2 / 1 span / 1 span;
  padding-bottom: 7px;
}

.profile .player-tones .tone-count .count {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(#80ffdb 0%, #48b2be 100%);
  -webkit-background-clip: text;
  background-clip: text;
  margin-right: 10px;
  font-size: 54px;
  font-weight: 500;
  line-height: 1;
}

.profile .player-tones .tone-count .text {
  color: #fff;
  vertical-align: super;
  font-size: 24px;
}

.profile .player-tones .tone-count-unique {
  grid-area: 3 / 1 span / 1 span;
  font-size: 13px;
  font-weight: 400;
}

.profile .player-tones .tone-count-unique .count {
  letter-spacing: 1px;
  margin-right: 4px;
  font-weight: bold;
}

.sign-in-page {
  width: 100%;
  height: 100%;
}

.sign-in-page .center-content {
  z-index: 10;
  width: 400px;
  max-width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.register-box {
  text-align: center;
  width: 370px;
  max-width: 95vw;
  padding: 60px 32px;
  transition: all 1s;
  animation: 1s ease-in fade-in;
}

.register-box .heading {
  margin-bottom: 42px;
}

.register-box .heading .gamename {
  color: #cbcbcb;
  letter-spacing: -.07em;
  font-family: Fira Sans;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.5;
}

.register-box .heading .subtitle {
  color: #b3b3b3;
  font-weight: 300;
}

.register-box .form > div {
  margin-bottom: 40px;
}

.register-box .form .message {
  margin-top: 6px;
  margin-bottom: 18px;
  font-size: 14px;
}

.register-box .actions button {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.register-box .actions button:not(:last-child) {
  margin-bottom: 26px;
}

.beta-welcome {
  color: #cbcbcb;
  padding: 48px;
}

.beta-welcome .main-welcome-message {
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 300;
}

.beta-welcome .notices {
  margin-top: 24px;
  margin-bottom: 36px;
}

.beta-welcome .notices .notice {
  padding-left: 12px;
  font-size: 16px;
  line-height: 1.4;
  position: relative;
}

.beta-welcome .notices .notice .bullet {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  left: -8px;
}

.beta-welcome .notices .notice:not(:last-child) {
  margin-bottom: 12px;
}

.register-page {
  width: 100%;
  height: 100%;
}

.register-page .center-content {
  z-index: 1;
  width: 400px;
  max-width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.register-page .message {
  color: #b3b3b3;
  text-align: center;
  font-size: 18px;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

html, body {
  background-color: #121212;
  height: 100%;
  font-family: Fira Sans;
}

#root {
  height: 100%;
}
/*# sourceMappingURL=index.03372027.css.map */
