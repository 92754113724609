@import "../colors.scss";
@import "../keyframes.scss";

#result {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: #fffffff7;
  // background: linear-gradient(0deg, #e3ffe7f7 0%, #d9e7fff7 100%);
  overflow-y: scroll;
  padding: 40px;

  .game-class-splash {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 1;
    pointer-events: none;

    .splash-content {
      position: relative;
      top: 50%;
      text-align: center;
      transform: translateY(-50%);
    }

    .message {
      font-size: 128px;
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(9, 9, 121, 1) 35%,
        rgba(0, 212, 255, 1) 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .result-content {
    z-index: 1;
    width: 100%;
    text-align: center;
    border-radius: 3px;
    max-width: 1100px;
    margin: 0 auto;

    .result-row {
      .title {
        font-family: "Fira Sans";
        font-weight: 300;
        text-transform: uppercase;
        font-size: 21px;
        margin-bottom: 16px;
        color: map-get($gray, 70);
        width: 100%;
        position: relative;
        text-align: center;
        &::after {
          content: "";
          height: 1px;
          width: 176px;
          background: $neon;
          position: absolute;
          bottom: 0;
          left: calc(50% - 88px);
        }
        i {
          font-size: 12px;
          vertical-align: middle;
        }
      }

      &:not(:last-child) {
        margin-bottom: 90px;
      }

      &.winner {
        text-align: center;
        font-family: "Fira Sans";
        img {
          position: relative;
          display: block;
          margin: 0 auto -16px;
          z-index: 999;
        }
        .text {
          font-size: 16px;
          margin: 16px 0 130px;
          font-weight: 400;
          color: map-get($gray, 60);
        }
        .name {
          font-family: "Fira Mono";
          margin-top: 8px;
          font-size: 32px;
          font-weight: bold;
          color: #fff;
          text-shadow: 0 0 4px #00000080;
          display: inline-block;
          padding: 8px 16px;
        }
        .message {
          margin-top: 24px;
          .ranking {
            font-weight: bold;
          }
        }
      }
    }

    .result-row.actions {
      margin-top: -32px;

      button {
        display: inline-block;

        &:not(:last-child) {
          margin-right: 36px;
        }
      }
    }

    .result-sections {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding-top: 36px;
      gap: 100px;
    }

    .ranking {
      flex: 0 1 600px;

      .rewards {
        border: 1px solid map-get($gray, 20);
        border-radius: 2px;
        color: map-get($gray, 70);
        margin-bottom: 48px;

        .player-profile-bar {
          background-color: map-get($gray, 20);
          display: flex;
          justify-content: space-between;
          padding: 16px;

          .player-profile {
            font-size: 11px;
            vertical-align: middle;
            > * {
              display: inline-block;
              vertical-align: middle;
            }

            .chart-wrapper {
              width: fit-content;
            }

            .level-tag, .chart-wrapper {
              margin-right: 12px;
            }

            .next {
              margin-right: 6px;
              color: map-get($gray, 80);
              font-weight: bold;
            }

            .level-up-message {
              font-size: 14px;
              color: $neon;
              animation: floating 1s infinite ease-in-out;
            }
          }
        }
      }

      table {
        border-spacing: 0;
        width: 100%;
        tr {
          box-shadow: inset 0 -1px map-get($gray, 20);
        }
        thead {
          th {
            text-align: right;
            font-family: "Fira Sans";
            font-weight: 400;
            color: $text-secondary;
            text-transform: uppercase;

            &.left {
              text-align: left;
            }
          }

          tr th {
            font-size: 12px;
            padding: 8px 4px;
          }

          tr th:first-child {
            padding-left: 0;
          }
          tr th:last-child {
            padding-right: 4px;
          }
        }
        tbody {
          tr td {
            padding: 4px 4px;
            height: 40px;
            font-family: "Fira Mono";
            font-weight: 400;
            font-size: 14px;
            color: $text-primary;
            text-align: right;

            .marker {
              display: inline-block;
              width: 16px;
              height: 16px;
              border: 1px solid #fff;
              vertical-align: middle;
              border-radius: 3px;
              margin-right: 6px;
            }
          }
          tr td:first-child {
            padding-left: 0px;
          }
          tr td:last-child {
            padding-right: 10px;
          }

          tr.my-row {
            background: map-get($gray, 0);
            td {
              font-weight: bold;
            }
          }

          td.col-ranking {
            font-weight: bold;
            font-family: "Fira Mono";
            font-size: 24px;
            color: map-get($gray, 30);
            text-align: center;
            text-align: right;
          }

          td.col-player-tag {
            text-align: left;

            span.level-up {
              color: $neon;
              font-size: 10px;
              font-family: 'Fira Mono';
              font-weight: bold;
              vertical-align: top;
              margin-left: 6px;
            }
          }
        }
      }
    }
    .result-wrapper {
      padding: 32px;
    }
    .board-wrapper {
      .board {
        width: 300px;
        height: 300px;
        border-radius: 2px;
      }
      .game-class {
        width: fit-content;
        padding: 32px 24px 22px;
        box-sizing: border-box;
        backdrop-filter: blur(1px);
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid map-get($gray, 0);
        border-radius: 2px;
        position: relative;
        top: -206px;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.5;
        text-transform: uppercase;
        margin: 0 auto -111px;
        color: #fff;

        img {
          position: absolute;
          top: -15px;
          width: 36px;
          left: calc(50% - 18px);
        }
        .smaller {
          text-transform: none;
          font-weight: 400;
          font-size: 12px;
        }
      }
      .divider {
        height: 24px;
        width: 1px;
      }
      .stats {
        width: 364px;
        text-align: left;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: 16px 26px;

        .stat {
          flex: 0 1 calc(33% - 16px);
          padding: 12px;
          text-align: center;
          margin-bottom: 4px;
          position: relative;
          background: map-get($gray, 20);
          border-radius: 8px;
          color: map-get($gray, 80);
          display: flex;
          flex-direction: column;

          .stat-display-name {
            font-size: 14px;
            line-height: 1.5;
            font-weight: 400;
            text-transform: uppercase;
            flex: 1;

            .lStat {
              font-size: 14px;
              text-align: center;
              vertical-align: middle;
              display: inline-block;
              position: relative;
              font-weight: 700;
              padding-right: 2px;
              text-decoration: underline;
              text-decoration-style: dotted;

              &:hover {
                .tooltip {
                  font-size: 10px;
                  display: block;
                  font-weight: 400;
                }
              }
              .tooltip {
                padding: 6px 8px;
                background: map-get($gray, 80);
                color: #fff;
                display: none;
                position: absolute;
                top: -30px;
                left: 50%;
                transform: translateX(-50%);
                white-space: nowrap;

                &::after {
                  content: "";
                  border-top: 8px solid map-get($gray, 80);
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  position: absolute;
                  bottom: -8px;
                  left: calc(50% - 9px);
                }
              }
            }
          }

          .stat-value {
            font-weight: 700;
            font-size: 16px;
            margin-top: 8px;
          }

          &.objective {
            // background-color: #bbbb;
            // color: #fff;

            &.satisfied {
              background: map-get($gray, 70);
              border: 1px solid map-get($gray, 80);
              color: #fff;
            }
          }
        }
      }
    }

    .rewards {
      .type {
        display: inline-block;
        margin-right: 12px;
        vertical-align: top;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .tones-link {
        color: inherit;
        font-size: 12px;
      }
    }
  }

  .import-tone-stack {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #121212f4;
    color: #fff;

    .received-tones {
      width: 600px;
      max-width: 100%;
      max-height: calc(100% - 50px);
      overflow-y: auto;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 2px;
      border: 1px solid map-get($gray, 20);
      z-index: 9;

      .actions {
        .button {
          margin-left: auto;
          margin-right: auto;
          margin-top: 24px;
        }
      }
    }
  }

  .received-tones {
    background-color: rgba(0, 0, 0, 0.35);
    padding: 24px 0 48px 0;

    img {
      margin-bottom: 32px;
    }

    .message {
      font-family: "Fira Sans";
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 12px;

      .reason {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 24px;
      }
      .tone-number {
        color: $neon;
        font-weight: bold;
      }
    }

    .reward-tones {
      display: inline-block;
      vertical-align: top;
      max-width: 300px;
      text-align: left;
      .reward-tone, .reward-tone-placeholder {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin: 4px;
        border-radius: 2px;
      }
      .reward-tone {
        border: 1px solid map-get($gray, 80);
      }
    }
  }

  .confetti-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2;

    & > div {
      position: absolute;
    }
  }

  $colors: (#d13447, #ffbf00, #263672);

  @for $i from 0 through 100 {
    $w: random(8);
    $l: random(100);
    .confetti-#{$i} {
      width: #{$w}px;
      height: #{$w * 0.4}px;
      background-color: nth($colors, random(3));
      top: -10%;
      left: unquote($l + "%");
      opacity: random() + 0.5;
      transform: rotate(#{random() * 360}deg);
      animation: drop-#{$i}
        unquote(4 + random() + "s")
        unquote(random() + "s")
        infinite;
    }

    @keyframes drop-#{$i} {
      100% {
        top: 110%;
        left: unquote($l + random(15) + "%");
      }
    }
  }
}
