@import "../colors.scss";

.chart-wrapper {
  width: 100%;
  .title-wrapper {
    text {
      font-family: "Fira Sans";
      dominant-baseline: hanging;
    }
    .chart-title {
      font-size: 12px;
    }
    .chart-values {
      font-size: 14px;
      font-weight: 300;
    }

    .pie-value {
      font-weight: 500;
      font-size: 36px;
    }
  }
  .chart-bar .chart-values {
    text-anchor: end;
  }

  .chart-donut .title-wrapper {
    text-anchor: middle;
  }
}
