@import "../colors.scss";

.beta-welcome {
    padding: 48px;
    color: map-get($gray, 60);

    .main-welcome-message {
        font-size: 28px;
        font-weight: 300;
        margin-bottom: 24px;
    }

    .notices {
        margin-top: 24px;
        margin-bottom: 36px;
        .notice {
            padding-left: 12px;
            font-size: 16px;
            line-height: 1.4;
            position: relative;

            .bullet {
                position: absolute;
                font-size: 18px;
                font-weight: bold;
                left: -8px;
            }
        }
        .notice:not(:last-child) {
            margin-bottom: 12px;
        }
    }
}
