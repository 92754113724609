@keyframes pulse-opacity {
    0% {
        opacity: 0.3;
    }

    30% {
        opacity: 0.7;
    }

    70% {
        opacity: 0.7;
    }

    100% {
        opacity: 0.3;
    }
}

@keyframes pop {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(50px, 60px);
    }
}

@keyframes simple-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes simple-fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes floating-fading {
    0% {
        opacity: 0;
        bottom: 0%;
    }

    10% {
        opacity: 1;
        bottom: 20%;
    }

    90% {
        opacity: 1;
        bottom: 80%;
    }

    100% {
        opacity: 0;
        bottom: 100%;
    }
}
@keyframes size-smaller {
    0% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes pulse-scale {
    0% {
        transform: scale(1);
    }

    70% {
        transform: scale(1.15);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes expand-fade {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    90% {
        transform: scale(1.7);
        opacity: 1;
    }
    100% {
        transform: scale(1.7);
        opacity: 0;
    }
}

@keyframes fade-as-shrink {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    90% {
        transform: scale(0.6);
        opacity: 0.1;
    }
    100% {
        transform: scale(0.6);
        opacity: 0;
    }
}

@keyframes slide-in-right {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slide-in-bottom {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes my-marker-appear {
    0% {
        opacity: 0;
        transform: scale(20);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes marker-horizontal-shaking {
    0% {
        transform: translateX(0);
    }

    15% {
        transform: translateX(1px);
    }

    30% {
        transform: translateX(-1px);
    }

    45% {
        transform: translateX(1px);
    }

    60% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes floating {
    from {
        transform: translate(0, -1px);
    }

    65% {
        transform: translate(0, 3px);
    }

    to {
        transform: translate(0, -1px);
    }
}

@keyframes appear-spin {
    from {
        transform: rotate(45deg);
        opacity: 0;
    }

    25% {
        transform: rotate(130deg);
    }

    50% {
        transform: rotate(225deg);
        opacity: 1;
    }

    75% {
        transform: rotate(315deg);
        opacity: 1;
    }

    to {
        transform: rotate(405deg);
        opacity: 0.25;
    }
}

@keyframes spin-slide-l {
    0% { opacity: 0; transform: translateX(100%); }
    92% { opacity: 0.8; transform: translateX(0%); }
    100% { opacity: 0; transform: translateX(0%); }
}

@keyframes spin-slide-r {
    0% { opacity: 0; transform: translateX(-100%); }
    92% { opacity: 0.8; transform: translateX(0%); }
    100% { opacity: 0; transform: translateX(0%); }
}

@keyframes spin-slide-u {
    0% { opacity: 0; transform: translateY(100%); }
    92% { opacity: 0.8; transform: translateY(0%); }
    100% { opacity: 0; transform: translateY(0%); }
}

@keyframes spin-slide-d {
    0% { opacity: 0; transform: translateY(-100%); }
    92% { opacity: 0.8; transform: translateY(0%); }
    100% { opacity: 0; transform: translateY(0%); }
}