@import '../colors.scss';

.board-with-status {
    position: relative;
    
    .flex-row {
        display: flex;
        justify-content: center;

        @media screen and (max-width: 1200px){
            padding-right: 200px;
        }
    }

    .players {
        height: 100%;
        display: flex;
        flex-direction: column;

        &.players-left {
            text-align: right;
            padding-right: 24px;
            flex-wrap: wrap-reverse;

            .player-tag {
                margin-left: 21px;
            }
        }

        &.players-right {
            padding-left: 24px;
            flex-wrap: wrap;

            .player-tag {
                margin-right: 21px;
            }
        }

        .player-tag {
            height: 64px;
            opacity: 1;
            transition: opacity 1s;
            padding-top: 14px;
            flex: 0 1 64px;
            position: relative;
            
            .player-flag {
                display: inline-block;
                width: 36px;
                height: 36px;
                line-height: 32px;
                border-radius: 4px;
                vertical-align: middle;
                text-align: center;
                font-weight: bold;
                color: #2229;
                border: 2px solid #7774;
            }

            &.dark {
                .player-flag {
                    color: #fffd;
                }
            }

            &:not(.eliminated) .player-flag {
                box-shadow: 0 0 0 0 rgba(200, 200, 200, 1);
                // animation: pulse 2s infinite;
            }
            
            &.eliminated {
                opacity: 0.05;
            }
            
            // span { //?? Cannot find corresponding item
            //     font-size: 16px;
            //     vertical-align: middle;
            // }

            .level-tag {
                position: absolute;
                right: -8px;
                top: 4px;
            };
        }
    }

    .board {
        // border: 8px solid #fff0;
        border-radius: 5px;
        background-color: transparent;
        border: 1px solid map-get($gray, 40);
    }

    .canvas-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        .player-marker {
            position: absolute;
            border-radius: 50%;
            // border-style: solid;
            transition: ease-in-out 0.7s all;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);

            .player-marker-inner {
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                .card-bubble {
                    text-align: center;
                    font-size: 16px;
                    font-weight: bold;
                    width: 36px;
                    padding: 2px 0;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -100%);
                    background: #fff;
                    z-index: 1;
                    border-radius: 2px;
                    animation: fade-out 2s 1;
                    opacity: 0;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        width: 0;
                        height: 0;
                        border: 6px solid transparent;
                        border-top-color: #fff;
                        border-bottom: 0;
                        margin-left: -6px;
                        margin-bottom: -5px;
                    }
                }
            }
        }
    }

    @keyframes pulse {
        0% {
          transform: scale(0.92);
          box-shadow: 0 0 0 0 #d69f9edb;
        }
        
        70% {
          transform: scale(1);
          box-shadow: 0 0 0 8px #f2848200;
        }
        
        100% {
          transform: scale(0.92);
          box-shadow: 0 0 0 0 #f2848200;
        }
    }
    
    @keyframes fade-in {
        0% {
          opacity: 1;
          color: #91f3fc;
        }
      
        100% {
          opacity: 1;
          color: #222;
        }
    }
    
      @keyframes fade-out {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}
