@import '../keyframes.scss';

.dark-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #121212;

    .gradation {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 2;
        width: 100%;
        height: 50%;
        background: linear-gradient(170deg, rgba(0,0,0,0) 0%, rgba(11,28,29,0) 42%, rgba(18,46,47,0.8) 73%, rgba(38,98,101,0.9) 90%);
        // background: linear-gradient(170deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 55%, rgba(23,60,62,1) 69%, rgba(38,98,101,1) 81%, rgba(38,98,101,1) 100%);
    }

    .squares {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        width: 100%;
        height: 30%;
        overflow: hidden;

        .square {
            position: absolute;
            bottom: 50%;
            width: 11px;
            height: 11px;
            border-radius: 2px;
            animation: floating-fading 20s 1 ease-out forwards;

            &.pattern-0{
                background: linear-gradient(79.56deg, #232F43 7.78%, #2F2442 80.6%, #22515A 126.27%);
            }

            &.pattern-1 {
                background: linear-gradient(79.56deg, #223949 7.78%, #3A2245 75.66%, #2F2442 126.27%);
            }

            &.pattern-2 {
                background: linear-gradient(79.56deg, #266265 7.78%, #232F43 75.66%, #2F2442 126.27%);
            }
        }
    }
}