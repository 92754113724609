@import '../colors.scss';

.game-reward {
    color: $text-secondary;
    // width: 110px; Width is set in the code
    max-width: 100%;
    font-family: "fira sans";
    font-size: 14px;
    letter-spacing: 0.8px;
    position: relative;

    .title {
        text-transform: uppercase;
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 1.3;
    }

    .row {
        text-align: right;
        margin-bottom: 12px;

        img {
            width: 20px
        }

        .label {
            font-size: 12px;
            font-weight: 300;
            vertical-align: bottom;
            text-wrap: nowrap;
            margin-right: 8px;
        }

        .value {
            font-weight: 700;
            color: $text-primary;
            font-size: 16px;
            vertical-align: bottom;
            
            &.class-0 {
                color: $text-disabled;
            }
        }

        &.my-share {
            padding-bottom: 16px;
            border-bottom: 1px solid map-get($gray, 40);
        }

        &.tones {
            .expected-tones {
                font-size: 18px;
                font-weight: 700;
                color: $text-primary;

                &.class-0 {
                    color: $text-disabled;
                }
            }
            .label {
                font-size: 14px;
                margin-right: 0;
                line-height: 1.5;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .explanation-box {
        position: absolute;
        top: 46px;
        right: -110px;
        .explanation-inner {
            padding: 8px;

            &:before {
                content: ' ';
                position: absolute;
                width: 0;
                height: 0;
                left: -14px;
                right: auto;
                top: 62px;
                bottom: auto;
                border: 7px solid;
                border-color: transparent #111 #111 transparent;
            }
            &:after {
                content: ' ';
                position: absolute;
                width: 0;
                height: 0;
                left: -14px;
                right: auto;
                top: 75px;
                bottom: auto;
                border: 7px solid;
                border-color: map-get($gray, 20) map-get($gray, 20) transparent transparent;
            }
        }
        &:before {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            left: -14px;
            right: auto;
            top: 62px;
            bottom: auto;
            border: 7px solid;
            border-color: transparent map-get($gray, 20) map-get($gray, 20) transparent;
        }
        &:after {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            left: -13px;
            right: auto;
            top: 75px;
            bottom: auto;
            border: 7px solid;
            border-color: #111 #111 transparent transparent;
        }
    }

    .flag {
        i {
            padding-left: 4px;
        }
    }

}