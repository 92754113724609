@import "../colors.scss";
$card-width: 64px;
$card-height: 80px;
$card-l: #4e4e7c;
$card-u: #8e3a96;
$card-d: #447292;
$card-r: #48b2be;

.card-deck {
  position: relative;
  padding-top: 32px;
  padding-bottom: 18px;
  text-align: center;

  .card-spot-container {
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: center;

    .card-spot {
      display: inline-block;
      width: $card-width;
      height: $card-height;
      margin-left: 7px;
      margin-right: 8.5px;
    }
    .separator {
      display: inline-block;
      height: 50px;
      width: 1px;
      margin: 0 12px;
      background: map-get($gray, 50);
    }
  }

  .card-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .card {
      display: inline-block;
      width: $card-width;
      height: $card-height;
      border-radius: 4px;
      background: #fff;
      transition: 0.2s;
      cursor: pointer;
      position: absolute;
      user-select: none;
      border: 2px solid rgba(255, 255, 255, 0);
      margin-right: 7.5px;

      .card-direction {
        position: absolute;
        top: -7.5px;
        right: -7.5px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background: black;
        color: #fff;
        font-size: 18px;
        line-height: 1;
        text-align: center;
        user-select: none;
        transition: 0.2s;

        &.direction-l {
          background-color: $card-l;
        }
        &.direction-u {
          background-color: $card-u;
        }
        &.direction-d {
          background-color: $card-d;
        }
        &.direction-r {
          background-color: $card-r;
        }

        i::before {
          font-weight: bold !important;
        }
      }

      .card-header {
        display: flex;
        height: 30px;
        padding: 6px 2px 0;
        justify-content: center;
        position: relative;

        .card-keynum {
          position: absolute;
          top: -10px;
          left: -7px;
          padding: 1px 2px;
          border-radius: 2px;
          font-size: 12px;
          background: map-get($gray, 30);
          color: #fff;
          z-index: 9;
          user-select: none;
        }

        .card-count {
          margin-top: 1px;
          display: block;
          font-size: 12px;
          font-weight: bold;
          color: map-get($gray, 30);
          user-select: none;
          i {
            padding-right: 1px;
          }
        }
      }

      .card-num {
        display: block;
        height: 50px;
        line-height: 1.2;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        user-select: none;
      }

      &.selected {
        color: #fff;
        transform: translateY(-12px);
        .card-count {
          color: #fff;
        }
      }

      &:hover,
      &.selected {
        .card-direction {
          background: $white;
          font-weight: bold;
        }
      }

      &.direction-l.selected {
        background-color: $card-l;
      }
      &.direction-u.selected {
        background-color: $card-u;
      }
      &.direction-d.selected {
        background-color: $card-d;
      }
      &.direction-r.selected {
        background-color: $card-r;
      }

      &.direction-l:hover,
      &.direction-l.selected {
        border-color: $card-l;

        .card-direction {
          color: $card-l;
          outline: 2px solid $card-l;
        }
      }
      &.direction-u:hover,
      &.direction-u.selected {
        border-color: $card-u;
        .card-direction {
          color: $card-u;
          outline: 2px solid $card-u;
        }
      }
      &.direction-d:hover,
      &.direction-d.selected {
        border-color: $card-d;
        .card-direction {
          color: $card-d;
          outline: 2px solid $card-d;
        }
      }
      &.direction-r:hover,
      &.direction-r.selected {
        border-color: $card-r;
        .card-direction {
          color: $card-r;
          outline: 2px solid $card-r;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .card-deck {
    .card-container {
      .card {
        width: 42px;
        height: 56px;

        .card-direction {
          width: 16px;
          height: 16px;
          font-size: 16px;
        }

        .card-header {
          height: 20px;
          padding-top: 4px;
        }

        .card-num {
          font-size: 28px;
          height: unset;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
    .card-spot-container {
      .card-spot {
        width: 42px;
        height: 56px;
        margin: 0 2px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .separator {
        margin: 0 4px;
      }
    }
  }
}
