@import "../colors.scss";

.game-stat-card {
  width: 362px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 22px;
  gap: 16px;
  line-height: 1;
  color: $text-primary;

  img, .no-img {
    flex: 0 0 32px;
    max-height: 32;
    aspect-ratio: 1;
  }

  .no-img {
      border: 1px solid map-get($gray, 30)
  }

  .display-name {
    flex: 1;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
  }

  .stat-value {
    font-weight: 700;
    font-size: 16px;
  }

  &.accomplished {
    border: 1px solid $neon;
    .stat-value {
      color: $neon;
    }
  }

  &.md {
      width: 300px;
  }

  &.sm {
    width: 102px;
    flex-direction: column;
    gap: 20px;

    img {
        height: 36px;
        opacity: .5;
    }
    > div {
        opacity: .5;
    }
  }
}
