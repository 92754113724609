.tone-palette:not(.bucketized) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .color {
        width: 36px;
        height: 36px;
        margin: 2px;
        position: relative;
        border: 1px solid #fff;

        &.selected {
            border: 5px solid rgb(255, 155, 0);
        }

        .count {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0 4px;
            font-size: 12px;
            font-weight: bold;
            background: #fff;
            color: #222;
        }
    }
}
.tone-palette.bucketized {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    column-gap: 4px;
    .color-column {
        .tone {
            width: 16px;
            height: 16px;
            border-radius: 2px;
            opacity: 0;
        }
        .same-tone {
            padding: 1px;
            border: 3px solid #bbac;
            border-radius: 2px;
            margin-top: -4px;
            margin-bottom: -4px;
            margin-left: -4px;
            margin-right: -4px;
            opacity: 0;
        }
        *:not(:last-child) {
            margin-bottom: 3px;
        }
    }

    @for $i from 0 through 100 {
        .fade-in-#{$i} {
          animation: fade-in 0.5s calc(#{$i} * 0.03s) 1 forwards;
        }
    }

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
