@import '../colors.scss';

.main-container.center {
    display: flex;
    flex-direction: column;
    justify-content: center;;
    align-items: center;
    width: 100%;
    min-height: 100%;

    .content {
        z-index: 1;
        padding: 48px 12px;

        .tile {
            width: 600px;
        }
    }

    .story {
        max-width: 100%;
        color: map-get($gray, 80);
        padding: 48px 102px 54px 54px;
        font-size: 14px;
        line-height: 2.5;
        animation: fade-in 1s 1;
    }

    .menu {
        text-align: center;
        animation: fade-in-delayed 1.5s 1;
        margin-top: 36px;



        .nav-button {
            width: 120px;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 16px;
            font-weight: bold;

            &:not(:hover) {
                color: #303f9f;
            }
        }
    
        .button-wrapper {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 24px;
            }

            .nav-button {
                background-image: linear-gradient(to right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%);
                transition: font-size 0.2s;
    
                &:hover {
                    color: #fff;
                    animation: pulse 1s infinite ease-out;
                }
    
                @keyframes pulse {
                    0% {
                      transform: scale(0.92);
                      box-shadow: 0 0 0 0 #fcb4b3ef;
                    }
                    
                    40% {
                      transform: scale(1);
                      box-shadow: 0 0 0 8px #f2848200;
                    }
                    
                    100% {
                      transform: scale(0.92);
                      box-shadow: 0 0 0 0 #f2848200;
                    }
                }
            }
        }
    }

    .hall-of-fame-button {
        position: fixed;
        bottom: 16px;
        right: 24px;
        padding: 12px 16px;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        overflow: hidden;
        font-family: inherit;
        color: #fff;
        background-color: transparent;
        transition: background-color 0.5s;

        span, i {
            display: block;
            text-align: center;
        }

        i {
            font-size: 36px;
            margin-bottom: 8px;
            transition: all 0.3s;
        }

        span {
            font-size: 12px;
            letter-spacing: 0px;
        }

        .button-background {
            background: rgb(6, 4, 53);
            background: radial-gradient(circle, rgb(6, 4, 53) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
            animation: button-background-effect 5s infinite;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 600px;
            z-index: -1;
            transition: opacity 0.2s;
            opacity: 1;
        }

        &:hover {
            background-color: rgb(243, 171, 196);
            text-shadow: 0 0 4px #fff;
            i {
                transform: scale(1.15);
            }
            .button-background {
                opacity: 0.8;
            }
        }
    }

    .popup-wrapper {
        .how-to-popup {
            position: fixed;
            z-index: 99;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            button {
                margin: 12px auto 0 auto;
            }
        }
        .how-to-backdrop {
            position: fixed;
            z-index: 9;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(24, 24, 24, 0.9);
        }
    }

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fade-in-delayed {
        0% {
            opacity: 0;
        }
        30% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes button-background-effect {
        0% {
            transform: translateY(0) scale(1);
        }
        40% {
            transform: translateY(-400px) scale(1.5);
        }
        100% {
            transform: translateY(0) scale(1);
        }
    }
}
