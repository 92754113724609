@import '../colors.scss';

.landing {
    .center-content {
        width: 400px;
        max-width: 100%;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
    }
}
