@import "../colors.scss";
@import "../keyframes.scss";

.main-container.how-to {
    animation: simple-fade-in 0.5s 1 forwards linear;

    .how-to-inner {
        position: fixed;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;

        .header {
            .title {
                color: $text-secondary;
                text-transform: uppercase;
                margin-bottom: 8px;
                font-size: 20px;
                line-height: 1.3;
            }
        }

        .tile-wrapper {
            max-width: 100%;
            overflow-x: auto;
        }

        .actions {
            margin-top: 32px;
            text-align: center;
            .play-button-wrapper {
                display: inline-block;
            }
        }
    }
}
