@import "../colors.scss";
@import "../keyframes.scss";

.player-list {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 134px);
    border-right: 1px solid map-get($gray, 20);
    margin-right: auto;

    &:not(.collapsed) {
        max-width: 300px;
    }

    &.collapsed {
        width: 128px;
        .level,
        .name {
            display: none;
        }
    }

    .btn-row {
        display: flex;
        justify-content: flex-end;
        padding: 4px;
    }

    .row {
        display: flex;
        align-items: center;
        gap: 12px;
        height: 35px;
        font-family: "Fira Mono";
        font-size: 14px;
        color: $text-secondary;
        border-bottom: 1px solid map-get($gray, 20);
        padding: 0 14px 0 24px;

        &.header {
            font-family: "Fira Sans";
            font-size: 12px;
            font-weight: 400;
            font-variant: small-caps;
            letter-spacing: 0.6px;
        }

        &.target {
            background: map-get($gray, 20);
            height: 48px;

            .name {
                font-weight: bold;
                font-size: 15px;
            }
        }

        &.dead {
            opacity: 0.5;
        }

        &.approaching {
            background: rgba($alert-bg, .3);
        }
        .rank {
            font-family: "Fira Sans";
            flex: 0 0 15px;
            font-weight: 700;
            color: map-get($gray, 40);
            text-align: center;
        }

        .color-block {
            border-radius: 5px;
            width: 22px;
            height: 22px;
            flex: 0 0 22px;
        }

        &:not(.header) .color-block {
            border: 1px solid map-get($gray, 0);
        }

        .name,
        .point {
            font-weight: 400;
        }

        .name {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 13px;
        }

        .point {
            flex: 0 0 21px;
            text-align: right;
        }
    }

    &.entering {
        .row:not(.header) {
            animation: slide-in-bottom 0.3s 1 ease-out;
        }
    }
}