@import '../colors.scss';

.textfield {
    background: map-get($gray, 0);
    padding: 12px;
    margin: 18px 0;
    position: relative;
    font-size: 14px;
    line-height: 1;
    font-family: 'Fira Sans';
    border: 1px solid transparent;

    input {
        border: none;
        background: transparent;
        font-size: inherit;
        color: $text-primary;
        width: 100%;
        
        &:focus {
            outline: none;
        }
    }

    label {
        font-size: 14px;
        color: map-get($gray, 60);
        position: absolute;
        line-height: 1;
        top: 14px;
        left: 12px;
        transition: .3s;
    }
    
    .textfield-hint {
        font-size: 10px;
        padding-top: 8px;
        color: map-get($gray, 60);
        position: absolute;
        bottom: -18px;
        left: 0;
    }

    &.focused {
        background: transparent;
        border: 1px solid $neon;

        label {
            font-size: 10px;
            top: -16px;
            left: 0;
        }
        
    }
}