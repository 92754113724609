.icon-menu {
    display: inline-block;
    vertical-align: middle;

    & > * {
        &:not(:first-child) {
            margin-left: 16px;
        }
    }
    
    .icon-menu-button {
        display: inline-block;
        color: #333;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 16px;
        padding-bottom: 4px;
        border-bottom: 4px solid transparent;
        transition: border-color 0.2s;
        &:hover {
            border-color: #555;
        }
    }
}
