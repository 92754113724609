@import "../colors.scss";
@import "../keyframes.scss";

.game-status-msg {
  margin: 0 auto;
  text-align: center;
  font-family: Fira Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.16px;
  color: $text-secondary;
  white-space: pre-wrap;
  transition: all 0.3s;
  overflow: hidden;

  &.popup {
    position: fixed;
    top: calc(50% - 50px);
    height: 100px;
    width: 100%;
    left: 0;
    font-size: 24px;
    line-height: 100px;
    background-color: rgba(45, 45, 45, 0.6);
  }

  .message {
    .player-names {
      font-weight: 700;
    }
  
    &.status-message {
      animation: pulse-opacity 2s infinite;
    } 
  }

  &.event {
    .message {
      font-weight:bold;
      color: $neon;
      animation: size-smaller .5s 1 ease-in forwards;
    }
  }
  &.alert {
    .message {
      color: $alert-text;
      animation: pulse-scale 1s infinite ease-out;
    }
  }
}
