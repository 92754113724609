@import "../colors.scss";
@import "../keyframes.scss";

.knockout-bonus {
  color: $text-secondary;
  width: 220px;
  max-width: 100%;
  font-family: "fira sans";
  font-size: 10px;
  letter-spacing: 0.8px;

  .title {
    margin-bottom: 8px !important;

    img {
      width: 21px;
    }
  }

  .knockout-tones {
    .knockout-bonus-tone {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: 4px;
      border-radius: 2px;
      border: 1px solid map-get($gray, 80);
      text-align: center;
      color: #000;
      font-weight: bold;
      line-height: 16px;
      cursor: default;
      user-select: none;
      animation: slide-in-bottom 0.5s forwards;

      &.dark {
        color: #fff;
      }
    }
  }
}
