@import '../colors.scss';

.level-tag {
  background: linear-gradient(180deg, #223949 0%, #27273e 100%);
  border: 1px solid #223949;
  border-radius: 2px;
  display: flex;
  align-items: baseline;
  padding: 0 3px;
  gap: 4px;
  font-family: 'Fira Sans';
  line-height: 1.2;
  color: $text-secondary;

  .level-prefix {
    font-weight: 400;
    font-size: 10px;
  }
  .level-value {
    font-weight: 700;
    font-size: 12px;
    display: inline-block;
  }
  &:not(.small) {
    display: inline-block;

    .level-value {
      text-align: right;
    }
  }
  &.small {
    width: 18px;
    justify-content: center;
    .level-value {
      text-align: center;
    }
  }
}
