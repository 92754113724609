@import "../colors.scss";

button.button,
a.button {
  font-family: "Fira Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  color: map-get($gray, 60);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  outline: none;
  cursor: pointer;
  min-width: auto;
  min-height: auto;
  transition: 0.3s;
  border: 1px solid rgba(128, 255, 219, 0.3);

  &:not(.text-only) {
    background: map-get($gray, 10);
    box-shadow: -2px -2px 10px map-get($gray, 0), 4px 4px 10px map-get($gray, 0);
    border-radius: 24px;

    &:not(.disabled) {
      &:hover,
      &:focus {
        border: 1px solid $neon;
      }
    }

    &:active {
      box-shadow: none;
      background: none;
      border: 1px solid transparent;
      color: map-get($gray, 40);
    }

    &.disabled,
    &:disabled {
      color: $text-disabled;
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  &.icon-only {
    padding: 16px;
    color: $neon;
    border: 1px solid transparent;
    border-radius: 50%;

    &:not(.accent) {
      box-shadow: none;
      padding: 12px;
    }
    &.accent {
      background: map-get($gray, 0)
    }
    &:hover,
    &:focus {
      border: 1px solid $neon;
    }

    &:active {
      color: rgba(128, 255, 219, 0.3);
    }
  }

  &.text-only {
    background: none;
    box-shadow: none;
    border: 0px;

    &:hover,
    &:focus,
    &:active {
      color: map-get($gray, 60);
      text-decoration: underline;
      text-decoration-color: $neon;
      text-underline-offset: 6px;
    }

    &:active {
      color: map-get($gray, 40);
      text-decoration-color: rgba(128, 255, 219, 0.3);
    }
  }

  &.transparent {
    background: none;
    border: none;
    box-shadow: none;
  }
}

a.button {
  text-decoration: none;
}
