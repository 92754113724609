@import "../colors.scss";

.player-tag-wrapper {
    display: inline-block;
    padding: 8px;

    &.eliminated {
        opacity: 0.5;
    }

    .player-tag {
        font-weight: bold;
    }

    &.dark {
        color: map-get($gray, 80);
    }
}