.game-stat {
  width: 180px;
  position: relative;

  .display-name {
    text-align: left;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 4px;
  }
  
  .stat-value {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    color: #ababbf;
    transition: 0.3s all;

    &.in-range {
      color: #222;
      font-weight: bold;
      transform: scale(1.5);
    }
  }
}
