@import '../colors.scss';

.confirmation {
    display: block;
    padding: 16px;
    border-radius: 1px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%, -50%);

    .confirmation-inner {
        max-width: 400px;
        width: 100%;
        height: 100%;
        padding: 32px;

        .confirmation-message {
            color: $text-secondary;
        }

        .confirmation-actions {
            padding-top: 24px;
            display: flex;
            justify-content: space-around;
        }
    }
}

.confirmation-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #11111199;
}
