@import '../colors.scss';

.top-nav-left {
    position: fixed;
    top: 12px;
    left: 16px;
    z-index: 99;

    img {
        height: 26px;
        cursor: pointer;
    }
}
.top-nav-right {
    position: fixed;
    display: flex;
    top: 0;
    right: 16px;
    z-index: 99;
    .icon-menu {
        padding: 4px;
    }

    .exception-message {
        font-size: 12px;
        color: #eee2;
    }

    .logout-popup {
        position: absolute;
        top: 52px;
        right: 6px;
        width: max-content;
        text-align: center;
        color: $text-primary;
        .logout-popup-content {
            padding: 24px 64px 16px;
        }
        .message {
            font-family: 'Fira Sans';
            margin-bottom: 16px;
            font-size: 14px;
        }
        .button-row {
            button {
                display: inline-block;
                &:not(:last-child) {
                    margin-right: 16px;
                }
            }
        }
    }
}
