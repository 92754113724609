@import '../colors.scss';

.beta-leaderboard {
    .beta-leaderboard-section {
        padding: 24px;

        .title {
            img {
                margin-right: 8px;
                vertical-align: middle;
            }
            color: $text-secondary;
            font-size: 18px;
            text-transform: uppercase;
            text-align: center;
            font-family: "Fira Sans";
            margin-bottom: 16px;
        }

        &:not(:last-child) {
            margin-bottom: 12px;
        }

        table {
            border-spacing: 0;
            width: 100%;
            tr {
                box-shadow: inset 0 -1px map-get($gray, 20);
            }
            thead {
                th {
                    text-align: right;
                    font-family: "Fira Sans";
                    font-weight: 400;
                    color: $text-secondary;
                    text-transform: uppercase;
    
                    &.left {
                        text-align: left;
                    }
                }
    
                tr th {
                    font-size: 12px;
                    padding: 8px 4px;
                }
    
                tr th:first-child {
                    padding-left: 0;
                }
                tr th:last-child {
                    padding-right: 4px;
                }
            }
            tbody {
                tr td {
                padding: 4px 4px;
                height: 40px;
                font-family: "Fira Mono";
                font-weight: 400;
                font-size: 14px;
                color: $text-primary;
                text-align: right;
    
                .marker {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    border: 1px solid #fff;
                    vertical-align: middle;
                    border-radius: 3px;
                    margin-right: 6px;
                }
                }
                tr td:first-child {
                    padding-left: 0px;
                }
                tr td:last-child {
                    padding-right: 10px;
                }
    
                td.col-ranking {
                    font-weight: bold;
                    font-family: "Fira Mono";
                    font-size: 24px;
                    color: map-get($gray, 30);
                    text-align: center;
                    text-align: right;
                }
    
                td.col-player-tag {
                    text-align: left;
                }

                tr.my {
                    td.col-player-tag {
                        color: $neon;
                    }
                }

                tr.unranked {
                    td {
                        padding-top: 24px;
                    }
                }
            }
        }
    }
}